import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  email = 'bohm.richard@gmail.com';
  showEmail = false;

  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Kontakt');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Máš-li dotaz, neváhej kontaktovat pomocí emailu nebo linkedin.'});
  }


}
