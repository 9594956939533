import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { GamesService } from 'src/app/services/games/games.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PlayerGame } from 'src/app/models/player-game';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.css']
})
export class GamesComponent implements OnInit, OnDestroy {

  games: PlayerGame[];
  totalGames = 10;
  gamesPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50];

  username: string;
  private gamesSub: Subscription;

  constructor(private gamesService: GamesService, private authService: AuthService, private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Historie odehraných her');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Souhrn odehraných her s přehledem jak jednotlivé hry bodově ovlivnily celkové skóre'});

    this.username = this.authService.getUsername();
    this.gamesService.getGames(this.username, this.gamesPerPage, this.currentPage);
    this.gamesSub = this.gamesService.getGamesListener().subscribe((games) => {
      this.games = games.games;
      this.totalGames = games.length;
    });

  }

  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.gamesPerPage = pageData.pageSize;
    this.gamesService.getGames(this.username, this.gamesPerPage, this.currentPage);
  }

  showRatingDifference(value: number) {
    if (value < 0) {
      return '↘ ' + value;
    }
    if (value > 0) {
      return '↗' + value;
    }

    return value;
  }

  ngOnDestroy() {
    this.gamesSub.unsubscribe();
  }


}
