import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  private loginErrorSubs: Subscription;

  constructor(private authService: AuthService, private titleService: Title, private metaTagService: Meta) { }


  ngOnInit() {
    this.titleService.setTitle('Přihlášení');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Přihlaš se a získej možnost ovlivnit samotnou hru posíláním láskyplných nebo škodících zpráv.'});

    this.loginForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', [Validators.required, Validators.minLength(5)])
    });
    this.loginErrorSubs = this.authService.getLoginErrorListener().subscribe(data => {
      if (data === 'Wrong password') {
        this.loginForm.get('password').setErrors({wrongPassword: true});
      }
      if (data === 'Email doesnt exists') {
        this.loginForm.get('email').setErrors({emailNotFound: true});
      }
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.loginUser(this.loginForm.value.email, this.loginForm.value.password);
  }


  getEmailError() {
    if (this.loginForm.get('email').hasError('required')) {
      return 'Zadej email';
    }
    if (this.loginForm.get('email').hasError('email')) {
      return'Email nebyl zadán';
    }
    if (this.loginForm.get('email').hasError('emailNotFound')) {
      return 'Email neexistuje';
    }
  }

  getPasswordError() {
    if (this.loginForm.get('password').hasError('required')) {
      return 'Zadej heslo';
    }
    if (this.loginForm.get('password').hasError('minlength')) {
        return 'Heslo musí mít alespoň 5 znaků';
    }
    if (this.loginForm.get('password').hasError('wrongPassword')) {
      return 'Nesprávné heslo';
    }
  }

  ngOnDestroy() {
    this.loginErrorSubs.unsubscribe();
  }


}
