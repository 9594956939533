import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { QuestionService } from 'src/app/services/question/question.service';
import { Question } from 'src/app/models/question';
import { GetQuestion } from 'src/app/models/question-get';
import { UpdateQuestion } from 'src/app/models/question-update';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  form: FormGroup;
  categories: string[] = ['Herci', 'Zpěváci', 'Zvířata', 'Česká republika', 'Historie', 'Zeměpis', 'Přírodopis', 'Filmy a seriály',
                          'Věci', 'Sport', 'Zajímavosti'];
  categoriesMap: Map<string, string>;
  types: string[] = ['image', 'sound'];
  imagePreview: string;
  question: Question;
  getQuestion: GetQuestion;
  updateQuestion: UpdateQuestion;
  private mode = 'create';
  private questionId: string;

  constructor(private questionService: QuestionService,
              public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      'category': new FormControl('Herci'),
      'question': new FormControl(null),
      'type': new FormControl('image'),
      'image': new FormControl(null),
      'imageInfo': new FormControl(null),
      'privateInfo': new FormControl(null),
      'answers': new FormGroup({
        'correct': new FormControl(null),
        'wrong': new FormArray([])
      })
    });
    // just for reference - NOT USED ANYWHERE
    this.categoriesMap = new Map([['actors', 'Herci'], ['singers', 'Zpěváci'], ['animals', 'Zvířata'],
     ['czech-republic', 'Česká republika'],
     ['history', 'Historie'], ['geography', 'Zeměpis'], ['natural-history', 'Přírodopis'], ['movies-tvshows', 'Filmy a seriály'],
     ['things', 'Věci'], ['sport', 'Sport'], ['interest', 'Zajímavosti']]);

    this.addWrongAnswer();

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("id")) {
        this.mode = 'edit';
        this.questionId = paramMap.get("id");
        this.questionService.getQuestion(this.questionId).subscribe(questionData => {
          this.getQuestion = questionData;
          const wrongCount = this.getQuestion.answers.wrong.length;
          for (let i = 1; i < wrongCount; i++) {
            this.addWrongAnswer();
          }
          this.form.setValue({
            category: this.getQuestion.category,
            question: this.getQuestion.question,
            type: this.getQuestion.type,
            image: this.getQuestion.imagePath,
            imageInfo: this.provideFormValue(this.getQuestion.imageInfo),
            privateInfo: this.provideFormValue(this.getQuestion.privateInfo),
            answers: this.getQuestion.answers
          });
          this.imagePreview = this.getQuestion.imagePath;
          console.log(this.form);
        });
      } else {
        this.mode = "create";
      }
    });
  }

  getControls() {
    return (this.form.get('answers.wrong') as FormArray).controls;
  }

  addWrongAnswer() {
    const control = new FormControl(null);
    (this.form.get('answers.wrong') as FormArray).push(control);
  }

  provideFormValue(name: string) {
    console.log('name: ' + name);
    console.log('type: ' + typeof(name));
    if (name == null || name === undefined ) {
      console.log('returning null');
      return null;
    }
    return name;
  }

  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ image: file});
    this.form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
    console.log(file.name);
  }

  saveQuestion() {
    if (this.form.invalid) {
       return;
     }

    if (this.mode === 'create') {
      this.question = {
        category: this.form.value.category,
        type: this.form.value.type,
        question: this.form.value.question,
        image: this.form.value.image,
        imageInfo: this.form.value.imageInfo,
        privateInfo: this.form.value.privateInfo,
        answers: this.form.value.answers
      };

      this.questionService.addQuestion(this.question);
    } else {
      this.updateQuestion = {
        _id: this.questionId,
        category: this.form.value.category,
        type: this.form.value.type,
        question: this.form.value.question,
        image: this.form.value.image,
        imageInfo: this.form.value.imageInfo,
        privateInfo: this.form.value.privateInfo,
        answers: this.form.value.answers
      };

      this.questionService.updateQuestion(this.updateQuestion);
    }
    this.resetForm();
  }

  private resetForm(): void {
    const lastIndex = (this.form.get('answers.wrong') as FormArray).length - 1;
    for(let i = lastIndex; i > 0; i--){
      (this.form.get('answers.wrong') as FormArray).removeAt(i);
    }
    this.form.reset();
    this.form.get('answers.correct').markAsUntouched();
    this.form.get('question').markAsUntouched();
    this.form.markAsUntouched();
    this.form.reset();
  }

  showData() {
    console.log('category: ' + this.form.get('category').value);
    console.log('type: ' + this.form.get('type').value);
    console.log('question: ' + this.form.value.question);
    console.log('image: ' + this.form.value.image);
    console.log('answers: ' + this.form.value.answers);

    console.log('----------------------------------');
    console.log('category: ' + this.form.value.category);
    console.log('type: ' + this.form.value.type);
    console.log('question: ' + this.form.value.question);
    console.log('image: ' + this.form.value.image);
    console.log('answers: ' + this.form.value.answers);

    console.log('----------------------------------');
    this.resetForm();
  }
}
