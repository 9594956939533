<div class="wrapper">


  <mat-card>
    <mat-card-header>
      <mat-card-title>PRAVIDLA</mat-card-title>
    </mat-card-header>

    <div class="image">
      <img src="assets/images/icons/rules.svg">
    </div>

    <mat-card-content>
      <ul>
        <li>Hru může hrát jak registrovaný, tak neregistrovaný hráč. Registrovaný hráč získává řadu možností, jak ovlivnit samotnou hru - ať už posíláním
            náhodně vygenerovaných zpráv nebo tipováním špatných odpovědí soupeře
        </li>
        <li>Pokud po spuštění hry během 25 vteřin nedojde ke spojení s dalším online hráčem, může hráč využít možnost hrát proti botovi - počítači, jenž s určitou mírou náhody "tipuje"
            odpovědi. Bot vystupuje jako normální hráč, kterému se výhrou zvýší skóre, prohrou sníží.
        </li>
        <li>
          Samotná hra se skládá z pěti kol po 25 sekundách - každá hra tudíž nezabere více než 3 minuty.
        </li>
        <li>
          V každém kole vyber správnou odpověď na otázku. Pokud nevíš, tipni si - tímto nic neztratíš, maximálně získáš.
        </li>
        <li>
          Není důležité odpovědět na otázky v co nejkratším čase - faktor času není zohledněn do finálního výsledku.
        </li>
        <li>
          Za každou správnou odpověď na otázku získáš bod, za správné tipnutí, že soupeř odpoví v daném kole špatně, získáš také bod. Maximálně lze tedy dosáhnout 6 bodů.
        </li>
        <li>
          Zastrašující nebo láskyplné zprávy poslané během hry přímo neovlivňují výsledek, ale mohou zmást protivníka, který se přestane soustředit na otázku.
        </li>
        <li>
          Každý registrovaný hráč může prohlížet historii svých her a zjistit tak jak daná hra ovlivnila jeho skóre.
        </li>
        <li>
          10 hráčů s nejvyšším skóre se dostane do Síně slávy.
        </li>
        <li>
          Pokud hráč porazí protivníka s mnohem vyšším skóre, tím více mu jeho vlastní skóre poroste. Pokud však hráč prohraje s protivníkem s mnohem menším skóre, skóre se mu velkým
          skokem sníží. Pokud hráč hraje s hráčem s podobným skóre, v případě výhry či prohry se hráči zvýší/sníží skóre okolo 20 bodů.
        </li>
      </ul>
    </mat-card-content>

  </mat-card>

</div>
