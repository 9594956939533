import { Component, OnInit, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { GamesService } from 'src/app/services/games/games.service';
import { PlayerGame } from 'src/app/models/player-game';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit, OnDestroy {

  gamesCount: number;
  isPlatformBrowser: boolean = false;

  constructor(private titleService: Title, private metaTagService: Meta, private gamesService: GamesService, @Inject(PLATFORM_ID) private platfromId) {
  }

  ngOnInit() {
    this.titleService.setTitle('Online kvíz - hra pro více hráčů');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Vědomostní hra proti online soupeřům, hra pro dva hráče. Hrej proti náhodnému hráči nebo vyzvi svého kamaráda. Dostaň se do Síně slávy.'});
      this.metaTagService.updateTag(
        { property: 'og:image', content: 'https://onlinekviz.cz/assets/images/icons/svgi1.svg'});
    if (isPlatformBrowser(this.platfromId)) {
      this.isPlatformBrowser = true;
      this.gamesService.getGamesCount().subscribe(count => {
        this.gamesCount = count.count;
        });
      }
    }

  ngOnDestroy() {
  }

}
