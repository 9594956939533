import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PlayerGame } from 'src/app/models/player-game';
import { Subject } from 'rxjs';

const BACKEND_URL = environment.apiUrl;
const BACKEND_USERS_URL = BACKEND_URL + '/user/';
const BACKEND_GANMES_URL = BACKEND_URL + '/game/';

@Injectable({
  providedIn: 'root'
})
export class GamesService {

  private games = new Subject<{games: PlayerGame[], length: number}>();

  constructor(private http: HttpClient) { }

  getGames(username: string, gamesPerPage: number, currentPage: number) {
    const queryParams = `?gamessize=${gamesPerPage}&page=${currentPage}`;
    this.http.get<{games: PlayerGame[], length: number}>(BACKEND_USERS_URL + 'games/' + username + queryParams).subscribe(games => {
      this.games.next({games: games.games, length: games.length});
    });
  }

  getGamesListener() {
    return this.games.asObservable();
  }

  getTopPlayers() {
    return this.http.get<{username: string, rating: number}[]>(BACKEND_USERS_URL + 'toplist');
  }

  getLatestGames() {
    const queryParams = `?limit=10`;
    return this.http.get<PlayerGame[]>(BACKEND_GANMES_URL + 'getGames' + queryParams);
  }

  getGamesCount() {
    return this.http.get<{count: number}>(BACKEND_GANMES_URL + 'getGamesCount');
  }

}
