import { Component, OnInit, OnDestroy } from '@angular/core';
import { GamesService } from 'src/app/services/games/games.service';
import { PlayerGame } from 'src/app/models/player-game';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-latest-games',
  templateUrl: './latest-games.component.html',
  styleUrls: ['./latest-games.component.css']
})
export class LatestGamesComponent implements OnInit, OnDestroy {

  games: PlayerGame[] = [];
  displayedColumns: string[] = ['player1', 'result', 'player2', 'date'];
  getLatestGames: Subscription;

  constructor(private gamesService: GamesService) { }

  ngOnInit(): void {
    this.getLatestGames = this.gamesService.getLatestGames().subscribe(games => {
      this.games = games;
    });
  }

  ngOnDestroy(): void {
    this.getLatestGames.unsubscribe();
  }

  getDate(gameId: string, player2: string): string {
    const dateArray = gameId.split('-' + player2 + '-')[1].split('-');
    const date = new Date(Date.UTC(Number(dateArray[0]), Number(dateArray[1])-1, Number(dateArray[2]), Number(dateArray[3]), Number(dateArray[4]) ));
    const dateEdited = new Date(date.getTime());
    const hours = this.getProperTime(dateEdited.getHours().toString());
    const minutes = this.getProperTime(dateEdited.getMinutes().toString());
    return dateEdited.getDate() + '.' + (dateEdited.getMonth() + 1) + '.' + dateEdited.getFullYear() + ' ' + hours + ':' + minutes;
  }

  getProperTime(time: string): string {
    if (time.length === 1) {
      return '0' + time;
    } else {
      return time;
    }
  }

  getTrophy(p1points: number, p2points: number) {
    let emoji = '';

    if (p1points > p2points) {
      emoji = emoji + '🏆';

      if (p1points - p2points >= 3) {
        emoji = emoji +  '💥';
      }
    }

    if (p1points < p2points) {
      emoji = emoji + '😢';
    }
    return emoji;
  }

}
