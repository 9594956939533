import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Question } from 'src/app/models/question';
import { GetQuestion } from 'src/app/models/question-get';
import { UpdateQuestion } from 'src/app/models/question-update';
import { environment } from 'src/environments/environment';
import { MessagesService } from '../messages/messages.service';

const BACKEND_URL = environment.apiUrl + '/question/';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private http: HttpClient, private messageService: MessagesService) { }

  addQuestion(question: Question) {
    const postData = new FormData();

    postData.append("question", question.question);
    postData.append("category", question.category);
    postData.append("type", question.type);
    postData.append("answers", JSON.stringify(question.answers));
    postData.append("image", question.image);
    postData.append("imageInfo", question.imageInfo);
    postData.append("privateInfo", question.privateInfo);


    console.log('sending question:' + question);
    console.log('sending question:' + question.answers);
    console.log('correct answers: ' + question.answers.correct);
    console.log('wrong answers: ' + question.answers.wrong);
    console.log('sending image' + question.image);
    console.log('sending imageInfo' + question.imageInfo);
    console.log('sending privateInfo' + question.privateInfo);

    this.http.post(BACKEND_URL + 'addQuestion', postData).subscribe((data) => {
      this.messageService.openSnackBar('Úspěšně přidána nová otázka.', 'X', 5000);
    }, error => {
      this.messageService.openSnackBar('Chyba: ' + JSON.stringify(error), 'X', 5000);
    });
  }

  getQuestions(questionsPerPage: number, currentPage: number) {
    const queryParams = `?questionssize=${questionsPerPage}&page=${currentPage}`;
    return this.http.get(BACKEND_URL + 'getQuestions' + queryParams);
  }

  getQuestion(id: string) {
    return this.http.get<GetQuestion>(BACKEND_URL + 'getQuestion/' + id);
  }

  deleteQuestion(id: string) {
    this.http.delete(BACKEND_URL + 'deleteQuestion/' + id).subscribe((data) => {
      this.messageService.openSnackBar('Úspěšně smazána otázka.', 'X', 5000);
    }, error => {
      this.messageService.openSnackBar('Chyba: ' + JSON.stringify(error), 'X', 5000);
    });
  }

  updateQuestion(question: UpdateQuestion) {
    const postData = new FormData();
    postData.append("_id", question._id);
    postData.append("question", question.question);
    postData.append("category", question.category);
    postData.append("type", question.type);
    postData.append("answers", JSON.stringify(question.answers));
    postData.append("image", question.image);
    postData.append("imageInfo", question.imageInfo);
    postData.append("privateInfo", question.privateInfo);

    this.http.put(BACKEND_URL + 'updateQuestion/' + question._id, postData).subscribe((data) => {
      this.messageService.openSnackBar('Otázka úspěšně upravena.', 'X', 5000);
    }, error => {
      this.messageService.openSnackBar('Chyba: ' + JSON.stringify(error), 'X', 5000);
    });
  }

}
