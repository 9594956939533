import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { SocketService } from 'src/app/services/socket/socket.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

  @Input() myPlayerName: string;
  @Input() opponentName: string;
  @ViewChild('container') container: ElementRef;

  messages = [];
  chatForm: FormGroup;
  chatSubs: Subscription;
  leftTheChat = false;
  expandMessageBox = false;

  constructor(public socketService: SocketService) { }

  ngOnInit(): void {
    this.chatForm = new FormGroup({
      'message': new FormControl('', [])
    });

    this.chatSubs = this.socketService.getChatListener().subscribe(data => {
      if (data.type === 'message') {
        this.expandMessageBox = true;
        this.messages.push({name: this.opponentName, message: data.message});
      }
      if (data.type === 'leftTheChat') {
        this.messages.push({message: this.opponentName + ' opustil(a) chat.'});
        this.leftTheChat = true;
        this.chatForm.get('message').disable();
      }

    });
  }

  sendChatMessage() {
    if (this.leftTheChat) {
      return;
    }
    this.expandMessageBox = true;
    const message = this.chatForm.value.message;
    this.chatForm.reset();
    if (message !== '' && message != null) {
      this.messages.push({name: this.myPlayerName, message: message});
    }
    this.socketService.sendChatMessage(message);
  }

  private scrollToBottom() {
    // JUST IN CASE ngAfterViewInit and ngAfterViewChecked CAUSES ISSUES:       setTimeout(() => {
      this.container.nativeElement.scrollTop = this.container.nativeElement.scrollHeight;
    //}, 50);
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.socketService.leftTheChat();
    this.chatSubs.unsubscribe();
  }



}
