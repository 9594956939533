<mat-card class="admin-card">
  <mat-card-header>
    <mat-card-title>Nová otázka</mat-card-title>
    <mat-card-subtitle>Jdeme na to !!</mat-card-subtitle>
  </mat-card-header>

  <form [formGroup]="form" (submit)="saveQuestion()" class="admin-form">
      <mat-radio-group formControlName="category" class="example-radio-group">
        <mat-radio-button class="radio-button" *ngFor="let category of categories" [value]="category">
          {{category}}
        </mat-radio-button>
      </mat-radio-group>

    <mat-form-field class="full-width">
      <mat-label>Otázka</mat-label>
      <textarea matInput formControlName="question" required placeholder="Ex. Kdo je tohle? "></textarea>
    </mat-form-field>

    <mat-radio-group formControlName="type" class="example-radio-group">
      <mat-radio-button class="radio-button" *ngFor="let type of types" [value]="type">
        {{type}}
      </mat-radio-button>
    </mat-radio-group>

    <div>
      <button mat-stroked-button color="primary" type="button" (click)="filePicker.click()">Pick Image</button>
      <input type="file" #filePicker (change)="onImagePicked($event)">
    </div>

    <div class="image-preview" *ngIf="form.value.image && imagePreview !== '' && imagePreview && form.get('image').valid">
      <img [src]="imagePreview" [alt]="form.value.question">
    </div>

    <mat-form-field class="full-width">
      <mat-label>Info o obrázku</mat-label>
      <textarea matInput formControlName="imageInfo"></textarea>
    </mat-form-field>

    <mat-form-field class="full-width">
      <mat-label>Soukromé info k této otázce</mat-label>
      <textarea matInput formControlName="privateInfo"></textarea>
    </mat-form-field>

    <div formGroupName="answers">
      <mat-form-field class="full-width">
        <mat-label>Správná odpověď</mat-label>
        <input matInput formControlName="correct" required placeholder="Ex. Kristen Bell">
      </mat-form-field>

      <mat-form-field formArrayName="wrong" class="full-width" *ngFor="let w of getControls();  let i = index">
        <mat-label>Špatná odpověď</mat-label>
        <input matInput  [formControlName]="i" placeholder="Ex. Kristen Bell">
      </mat-form-field>

      <mat-card-actions>
        <button mat-button>SUBMIT</button>
        <button mat-button (click)="addWrongAnswer()" type="button">ADD WRONG ANSWER</button>
        <button mat-button (click)="showData()" type="button">Show Current Data</button>
      </mat-card-actions>
    </div>

  </form>
</mat-card>
