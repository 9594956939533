<div class="wrapper">

<mat-card class="card-wrapper">
<mat-card-header>
<div mat-card-avatar> <mat-icon>games</mat-icon></div>
<mat-card-title>MOJE HRY</mat-card-title>
<mat-card-subtitle>historie na dosah...</mat-card-subtitle>
</mat-card-header>
<mat-accordion>
  <mat-expansion-panel *ngFor="let game of games">
    <mat-expansion-panel-header>
      <mat-panel-title [ngClass]="{
        'win':game.result === 'win',
        'loss':game.result === 'loss',
        'equal':game.result === 'equal'
      }">
        {{game.result | result}}
      </mat-panel-title>
      <mat-panel-description>
        {{game.player1.name}} ({{game.player1.rating}})  {{game.player1.points}} : {{game.player2.points}} {{game.player2.name}} ({{game.player2.rating}})
      </mat-panel-description>
      <div class="rating-difference" [ngClass]="{
        'win-diff':game.result === 'win',
        'loss-diff':game.result === 'loss'
      }">{{showRatingDifference(game.ratingDifference)}}</div>
    </mat-expansion-panel-header>
    <div>{{game.player1.name}} ({{game.player1.rating}}): {{game.player1.points}}</div>
    <div>{{game.player2.name}} ({{game.player2.rating}}): {{game.player2.points}}</div>
  </mat-expansion-panel>
</mat-accordion>

<mat-paginator [length]="totalGames" [pageSize]="gamesPerPage" [pageSizeOptions]="pageSizeOptions" (page)="onChangedPage($event)"></mat-paginator>
</mat-card>

</div>
