import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit {

  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Pravidla');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Pravidla, zákonitosti a zajímavosti online hry pro dva hráče: onlinekviz.cz '});
  }

}
