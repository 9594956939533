import { Component, OnInit, Input } from '@angular/core';
import { Results } from 'src/app/models/gameResults';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {

  @Input() results: Results;

  constructor() { }

  ngOnInit(): void {
  }

  getType(type: string) {
    if (type === 'wrong') {
      return 'Soupeř odpoví špatně';
    } else {
      return type;
    }
  }



}
