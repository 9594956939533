import { Component, OnInit } from '@angular/core';
import { QuestionService } from 'src/app/services/question/question.service';
import { GetQuestion as Question } from 'src/app/models/question-get';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css']
})
export class AdminListComponent implements OnInit {

  totalQuestions = 10;
  questionsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50];

  questions: Question[] = [];
  constructor(private questionService: QuestionService) { }

  ngOnInit() {
    this.questionService.getQuestions(this.questionsPerPage, this.currentPage)
      .subscribe((data: {questions: Question[], length: number}) => {
        this.questions = data.questions;
        this.totalQuestions = data.length;
      });
  }

  delete(id: string) {
    this.questionService.deleteQuestion(id);
  }

  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.questionsPerPage = pageData.pageSize;
    this.questionService.getQuestions(this.questionsPerPage, this.currentPage)
      .subscribe((data: {questions: Question[], length: number}) => {
        this.questions = data.questions;
        this.totalQuestions = data.length;
      });
  }

}
