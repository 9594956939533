import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'result'
})
export class ResultPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value === 'win') { return 'Výhra'; }
    if (value === 'loss') { return 'Prohra'; }
    if (value === 'equal') {return 'Remíza'; }
    return value;
  }

}
