import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayService {

  private playCounterListener = new Subject<any>();
  private playInGameListener = new Subject<any>();

  constructor() { }

  getPlayListener() {
    return this.playCounterListener.asObservable();
  }

  getPlayInGameListener() {
    return this.playInGameListener.asObservable();
  }

  initCounter(round: number) {
    this.playCounterListener.next({type: 'init-counter', round: round});
  }

  startCounter() {
    this.playCounterListener.next({type: 'start-counter'});
  }

  stopCounter() {
    this.playCounterListener.next({type: 'stop-counter'});
  }

  submitAnswer() {
    this.playInGameListener.next({type: 'submit-answer'});
  }

}
