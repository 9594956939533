<div class="wrapper">
<mat-card class="card-wrapper">
  <mat-card-header style="text-align: center;">
    <div mat-card-avatar> <mat-icon>games</mat-icon></div>
    <mat-card-title>POSLEDNÍ HRY</mat-card-title>
    <mat-card-subtitle>Výsledky posledních her</mat-card-subtitle>
  </mat-card-header>

  <div class="image">
    <img src="assets/images/icons/lastgames.svg" alt="poslední odehrané kvízové hry">
  </div>

  <mat-card-content>

    <table *ngIf="games.length > 0" mat-table [dataSource]="games" class="table">

      <ng-container matColumnDef="player1">
        <th mat-header-cell *matHeaderCellDef> Hráč 1 </th>
        <td mat-cell *matCellDef="let game">{{game.player1.name}} {{getTrophy(game.player1.points, game.player2.points)}}</td>
      </ng-container>

      <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef style="padding-right: 10px; padding-left: 10px;"> Výsledek </th>
        <td mat-cell *matCellDef="let game" style="padding-right: 10px; padding-left: 10px;"> {{game.player1.points}} : {{game.player2.points}} </td>
      </ng-container>

      <ng-container matColumnDef="player2">
        <th mat-header-cell *matHeaderCellDef> Hráč 2 </th>
        <td mat-cell *matCellDef="let game"> {{game.player2.name}} {{getTrophy(game.player2.points, game.player1.points)}}</td>
      </ng-container>

      <ng-container matColumnDef="date" >
        <th fxHide.lt-sm="true" mat-header-cell *matHeaderCellDef> Datum </th>
        <td fxHide.lt-sm="true" mat-cell *matCellDef="let game"> {{getDate(game.gameId, game.player2.name)}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-card-content>
</mat-card>
</div>
