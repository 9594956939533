import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { _countGroupLabelsBeforeOption } from '@angular/material/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MessagesService } from 'src/app/services/messages/messages.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  isSamePassword: boolean;
  testing: string;
  form: FormGroup;

  constructor(private authService: AuthService, private messageService: MessagesService, private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Registrace');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Zaregistruj se a získej výhody pro hru - možnost ovlivnit samotnou hru posíláním láskyplných nebo škodících zpráv a tipováním špatných odpovědí soupeře'});

    this.testing="ahoj";
    this.form = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email]),
      'username': new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(15),
                                  this.checkReservedUsernames.bind(this)]),
      'password': new FormControl('', [Validators.required, Validators.minLength(5)]),
      'confirmedPassword': new FormControl('', [Validators.required, this.checkConfirmedPassword.bind(this)])
    });

  }

  onSignup(form: NgForm) {
    if (form.invalid) {
      return;
    }
    console.log(form.value.email + ' ' + form.value.password);
    //this.taskService.addTask(form.value.title, form.value.description, form.value.date);
  }


//template driven - not currently used
  onChange(form: NgForm) {
    /* JUST FOR DEBUGGING PURPOSES
    console.log(form.value.password);
    console.log(form.value.passwordRetype); */
    if (form.value.password === form.value.passwordRetype) {
      this.isSamePassword = true;
      console.log('stejne');
      console.log(this.isSamePassword);
    } else {
      this.isSamePassword = false;
      console.log('jine');
      console.log(this.isSamePassword);
    }
 }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.authService.createUser(this.form.value.email, this.form.value.username, this.form.value.password)
    .subscribe(
      (data) => {
        this.messageService.openSnackBar('Registrace proběhla úspěšně', 'X', 5000);
        this.authService.navigateToLogin();
      },
      (error) => {
        /* JUST FOR DEBUGGING PURPOSES
        console.log(error.error.error.errors);
        console.log(error); */
        if (error.error.error.errors.email) {
          if (error.error.error.errors.email.kind === 'unique') {
            this.form.get('email').setErrors({'emailNotUnique': true});
          }
        }
        if (error.error.error.errors.username) {
          if (error.error.error.errors.username.kind === 'unique') {
            this.form.get('username').setErrors({'usernameNotUnique': true});
          }
        }

      }

    );
   /*  this.authService.getErrorListener().subscribe((data) => {
      if (data === "unique") {
        this.form.get('email').setErrors({'emailNotUnique': true});
      }
    }); */
  }


  getEmailError() {
    if (this.form.get('email').hasError('required')) {
      return 'Zadej email';
    }
    if (this.form.get('email').hasError('email')) {
        return'Email nebyl zadan';
    }
    if (this.form.get('email').hasError('emailNotUnique')) {
      return 'Zadany email v systemu jiz existuje.';
    }
  }

  getUsernameError() {
    if (this.form.get('username').hasError('required')) {
      return 'Zadej uživatelské jméno';
    }
    if (this.form.get('username').hasError('minlength')) {
        return 'Uživatelské jméno musí mít alespoň 3 znaky';
    }
    if (this.form.get('username').hasError('maxlength')) {
      return 'Uživatelské jméno nemůže mít více než 15 znaků';
    }
    if (this.form.get('username').hasError('usernameNotUnique')) {
      return 'Uživatelské jméno již existuje v systému';
    }
    if (this.form.get('username').hasError('reservedUsername')) {
      return 'Uživatelské jméno vyhrazeno systémem';
    }
  }

  getPasswordError() {
    if (this.form.get('password').hasError('required')) {
      return 'Zadej heslo';
    } else {
      if (this.form.get('password').hasError('minlength')) {
        return 'Heslo musi mit alespon 5 znaku';
      }
    }
  }


  getConfirmedPasswordError() {
    if (this.form.get('confirmedPassword').hasError('required')) {
      return 'Zadej potvrzující heslo';
    } else {
      if (this.form.get('confirmedPassword').hasError('differentPasswords')) {
        return 'Potvrzujici heslo neni shodne';
      }
    }
  }


checkConfirmedPassword(control: AbstractControl): {[key: string]: boolean } {
   /* ANOTHER APPROACH
    if (control.value !== control.root.get('controls').get('password').value) {
    return {'samePasswords': true};
    } else {
    return null;
  } */

  if (this.form) {   //so we are sure form is initialized
    if (control.value !== this.form.get('password').value) {
      return {'differentPasswords': true};
      } else {
      return null;
    }
  }
}

checkReservedUsernames(control: AbstractControl): {[key: string]: boolean } {
  if (this.form) {
    if (control.value.toLowerCase().startsWith('anonymous') || (control.value.toLowerCase().startsWith('bot') && !this.form.get('email').value.includes('@bot.cz'))) {
      return {'reservedUsername': true};
    } else {
      return null;
    }
  }
}

}
