import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {

  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle('Jak se počítá skóre');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Jak se počítá uživatelské skóre při remíze, vyhrané či prohrané hře.'});
  }

}
