<div class="wrapper">


  <mat-card>
    <mat-card-header>
      <mat-card-title>Kontakt</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>
        Máš otázku ke hře, k samotnému projektu nebo chceš probrat něco jiného?
      </p>
      <p>Kontaktuj buď prostřednictvím sítě LinkedIn nebo emailem.</p>
    </mat-card-content>
    <mat-card-actions>
      <div>
      <a href="https://www.linkedin.com/in/richard-böhm-730b6a97">
        <button mat-raised-button color="primary">Linked <img src="/assets/images/icons/linkedin.png" height="12"/></button>
      </a>
      <button mat-raised-button color="primary" (click)="showEmail=!showEmail">E-mail <span>📧</span></button>
      <p *ngIf="showEmail">
        {{email}}
      </p>
    </div>

    </mat-card-actions>

  </mat-card>

</div>

