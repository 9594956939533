<div class="paragraph-leading">
  <div class="image">
    <img src="assets/images/icons/svgi1.svg" alt="online kvíz" class="center">
  </div>
  <div class="description">
    <button mat-raised-button color="accent" fxHide.gt-xs="true" routerLink="/hrej">HREJ TEĎ</button>
    <h1 fxHide.lt-sm="true" class="big-1 title-space">Souboj. Kvíz.</h1>
    <h1 class="big-1 title-mobile-space" fxHide.gt-xs="true">Souboj. Kvíz.</h1>
    <h2 class="big-4">Vědomostní hra proti online soupeřům. Probojuj se online kvízem až do síně slávy.</h2>
    <div style="text-align: center;">
      <button mat-raised-button color="accent" fxHide.lt-sm="true" routerLink="/hrej">HREJ TEĎ</button>
    </div>
  </div>
</div>

<div class="paragraph bgc-white">
  <mat-card class="card">
    <mat-card-content>
      <p class="big-2 center">Jedna hra. 5 otázek.</p>
      <div style="height: 55px"></div>
      <div class="center">
        <img src="assets/images/icons/happypart2.svg" loading="lazy" alt="kvíz">
      </div>
      <p class="big-3"> Každá jednotlivá hra Online kvízu má 5 otázek, časový limit na jednu otázku je 30 sekund. Jedna hra dohrána do 3 minut,
      ideální pokud se chceš rychle odreagovat a srovnat své znalosti s někým jiným. Cesta autobusem? Reklama v televizi? Zkus to.
    </p>
    </mat-card-content>
    <br><br>
    <div style="text-align: center;">
      <button mat-raised-button class="action" routerLink="/pravidla">PODÍVEJ SE NA PRAVIDLA</button>
    </div>
  </mat-card>

  <mat-card class="card">
    <mat-card-content>
      <p class="big-2 center">Zdolej soupeře a dostaň se do Síně slávy.</p>
      <div class="center">
        <img src="assets/images/icons/teamhappy.svg" loading="lazy" alt="zvýšení skóre">
      </div>
      <p class="big-3"> Každý registrovaný hráč Online kvízu hraje o body. Porazíš-li někoho, získáš body do tvého celkového skóre. Prohraješ-li, body ztratíš. Pokud porazíš hráče s mnohem vyšším skóre než je tvoje,
        tím více bodů můžeš získat. A naopak, pokud prohraješ s hráčem s mnohem menším skóre než je tvoje, tím více bodů ztratíš. Buduj své skóre a dostaň se do Síně slávy.
    </p>
    </mat-card-content>
    <div style="text-align: center;">
      <button mat-raised-button class="action" routerLink="/sin-slavy">ZOBRAZ SÍŇ SLÁVY</button>
    </div>
  </mat-card>
</div>

<div style="background-image: linear-gradient(white, rgba(235, 228, 134, 0.541), white);">

  <div style="text-align:center;">
    <img src="assets/images/icons/t6.svg"  loading="lazy" alt="zvýšení skóre" style="max-width: 1100px;">
  </div>
  
  <div *ngIf="isPlatformBrowser">
    <div class="title-middle-games">
      CELKEM ODEHRÁNO HER
      <p>
      <span class="games bgc2">{{gamesCount}}</span>
      </p>
    </div>

    <app-latest-games></app-latest-games>
  </div>
</div>


<div class="paragraph bgc-white">
  <div class="description">
    <p class="big-2">Není žádný protihráč? Hrej proti botovi</p>
    <p class="big-3"> Pokud tě během pár vteřin hra nepropojí s žádným protihráčem, nezoufej. Můžeš si zahrát kvíz proti botovi a i tímto způsobem si vylepšit své skóre. Ale pozor,
      botové mohou být velmi těžcí soupeři, i když jejich odpovědi jsou do značné míry dílem náhody.
    </p>
  </div>

  <div class="image">
    <img src="assets/images/icons/botbot.svg" loading="lazy" alt="online kvíz proti botům" class="center">
  </div>
</div>

<div class="paragraph">
  <div class="image">
    <img src="assets/images/icons/sendmessage.svg"  loading="lazy" alt="škození během hry" class="center">
  </div>

  <div class="description">
    <p class="big-2">Rozhoď protihráče posíláním láskyplných nebo zastrašujících zpráv</p>
    <p class="big-3"> Každý registrovaný hráč se může během hry pokusit rozhodit svého protihráče posláním náhodně vygenerované láskyplné nebo zastrašující zprávy. Taky máš možnost
      si tipnout, že v daném kole odpoví protihráč špatně a získat tak bonusový bod.
    </p>
  </div>
</div>

<div class="title-middle">
  NEMŮŽE BÝT TOHLE TVŮJ PŘÍPAD?
  <hr/>
</div>

<div class="story">
<!-- 1. story -->
<div class="story-wrapper">
  <div class="story-image">
    <img src="assets/images/avatars/favatar1.svg" loading="lazy" alt="avatar 1 online kvíz">
  </div>

  <div class="story-content bgc1">
    Miluji ten pocit, kdy je večer, děti spí, všude klid, a já se rozvalím na gauč, vezmu do ruky mobil a najedu na online kvíz, zmáčknu tlačítko hrej a můžu si zahrát proti úplně cizímu člověku kterého neznám. Do tří minut je kvízová hra dohrána, a tak kdyby bylo potřeba cokoli v domácnosti udělat, není problém. Klidně můžu péct buchtu a během toho si zahrát pár her, to je na tom to skvělé. Když zrovna není žádný protihráč online, můžu se pokusit zahrát proti botovi, ale raději hraju proti živému člověku, je to mnohem větší sranda, už i díky tomu posílání škodících zpráv, které dokážou být celkem srandovní. Skóre sice nemám nejlepší, ale mi nejde o vavříny, chci se pobavit a odreagovat – ale stejně nejvíc mě potěší, když vím nějakou těžkou otázku, o které soupeř nemá ani tucha.
  </div>
</div>

<!-- 2. story -->
<div class="story-wrapper reverse">
  <div class="story-content bgc2">
    Co tu napsat? Snad jen další hra pro dva, kde ale můžete hrát jak proti svému kámošovi, tak proti úplně cizím lidem, ke kterým vás hra přiřadí. Asi bych to neměl říkat, ale hraju nejčastěji v práci, kdy jí není moc a nudím se. Jednou mě to během práce spojilo s manželkou a ta poznala můj nick a furt mi teď říká jakou mám lehkou práci a ona musí skutečně dřít. No aspoň jsem ji tehdy porazil 😊 , o jeden bod, díky tipnutí že odpoví špatně na otázku o tanku. Obecně mám rád vědomostní hry, ale některé jsou moc dlouhé nebo vůbec nemají možnost hrát proti jiným, což je celkem nuda, takže proto jedině online kvíz, když nechci zbytečně ztrácet čas a když se chci dozvědět i něco nového. Jak už bylo na stránce psáno, jedna hra dohrána do tří minut. Co je tu dobře udělané je to, že není povolen chat s protihráčem během hry, ale až po skončení kvízové hry. Na internetu najdete všelijaké lidi, a ještě aby mě nějaký chytrolín otravoval svými moudry během samotné hry, kdy musím odpovědět na otázku do 30 vteřin, tak to fakt ne.
  </div>

  <div class="story-image">
    <img src="assets/images/avatars/favatar2.svg" loading="lazy" alt="avatar 2 online kvíz">
  </div>
</div>

<!-- 3. story -->
<div class="story-wrapper">
  <div class="story-image">
    <img src="assets/images/avatars/avatar3.svg" loading="lazy" alt="avatar 3 online kvíz">
  </div>

  <div class="story-content bgc1">
    Do školy jezdím autobusem asi 30 minut a když nenastoupí kámoši, tak vytáhnu mobil a zahraju si pár her. Mimoto, že rád posílám škodící zprávy, jsem měl jeden den v online kvízu otázku o Bonapartovi a zrovna jsme to pak ten den probírali ve škole 😊 Nikdy nevíš, kdy ti něco uvízne v hlavě a pak to můžeš použít pro svůj prospěch.
  </div>
</div>

<!-- 4. story -->
<div class="story-wrapper reverse">
  <div class="story-content bgc2">
    Strašně pěkné obrázky zvířátek ve hře. To je taky důvod, proč tu hru tak ráda hraju. Hra má pět kvízových otázek, každá otázka je vždy doplněna o obrázek týkající se tématu a hlavně ta zvířátka jsou super. Když není žádný protihráč online, hra mě spojí po chvíli čekání s botem, ale s něma nerada hraju, někdy jsou až nerealisticky těžcí a skóre mi klesá. O to víc je ale pak super, když porazím někoho s mnohem vyšším skóre, to moje se tím pádem zvedne o hodně a soupeři celkem znatelně klesne.
  </div>

  <div class="story-image">
    <img src="assets/images/avatars/avatar4.svg" loading="lazy" alt="avatar 4 online kvíz">
  </div>
</div>

<!-- 5. story -->
<div class="story-wrapper">
  <div class="story-image">
    <img src="assets/images/avatars/avatar5.svg" loading="lazy" alt="avatar 5 online kvíz">
  </div>

  <div class="story-content bgc1">
    Hezký den všem kdo hrají nebo se chystají hrát tenhle online kvíz. Pryč jsou časy, kdy jsem hrál statické webové kvízy, tedy kvízy kde po dohrání vidím jen kolik jsem získal bodů z celkového počtu otázek. Prostě žádná interakce, předem dané otázky – to už mě nebaví. Tady je to jiné. Každá nová hra znamená jiný soupeř a nové otázky. Otázky jsou ze všech směrů a tak se klidně stane že někdy nevíte vůbec nic a někdy víte všechno. Škodící a láskyplné zprávy jsou taková úsměvná interakce, které používám, když někdy čekám na soupeřovu odpověď. Kde a kdy nejčastěji hraju? No, na to se nedá úplně přesně odpovědět, ale obecně když na něco čekám a nechci tu dobu zbytečně strávit prohlížením facebooku nebo instagramu. Takže nejčastěji určitě na mobilu. No, a takový malý zvyk, ráno o víkendu, kdy nemusím brzo vstávat, si tenhle online kvíz zahraju nejraději ještě v posteli. A prostě nevstanu, dokud někoho neporazím a neuvidím ty konfety po skončení hry.
  </div>
</div>

<!-- 6. story -->
<div class="story-wrapper reverse">
  <div class="story-content bgc2">
    Zdravím. Moje zkušenost s hrou je velmi pozitivní. Možná bych popřemýšlel nad zvednutím časového limitu pro otázku na třeba 45 sekund, ať je čas si otázku důkladně projít, ale zas na druhou stranu chápu, že by se tím hra mohla výrazně zpomalit a obzvláště zřetelné by to bylo při hře, kdy někdo odpovídá velmi rychle a protihráč až ke konci časového limitu. Hraju pouze doma na počítači, kdy mám všechno v domácnosti a na zahrádce porobeno a chci si prostě odpočinout a vypnout od běžných starostí, neřešit nic, jen relaxovat.
  </div>

  <div class="story-image">
    <img src="assets/images/avatars/avatar6.svg" loading="lazy" alt="avatar 6 online kvíz">
  </div>
</div>

<!-- 7. story -->
<div class="story-wrapper">
  <div class="story-image">
    <img src="assets/images/avatars/avatar7.svg" loading="lazy" alt="avatar 7 online kvíz">
  </div>

  <div class="story-content bgc1">
    Čaueees, jako jo, hra je super, ale ještě suprovější by bylo přidání více škodících tlačítek, jako pokud by to šlo poslat místo textu třeba nějaký gif na 2 sekundy soupeři 😊 Jako možná by to bylo moc, to by chtělo vyzkoumat aby to zas neškodilo hře samotné, ale určitě přidání něčeho škodícího by bylo jako fajn. K otázce jestli hraju na mobilu nebo počítači je jasná odpověď, počítač nemám, všichni dneska jedou na mobilu, ne?
  </div>
</div>

<!-- 8. story -->
<div class="story-wrapper reverse">
  <div class="story-content bgc2">
    Fakt super hra když čekám na autobus nebo tramvaj a nechci furt checkovat face a instáč. Přijde mi, že tenhle online kvíz je mnohem zábavnější než jiné kvízy, protože tady se hraje proti online soupeřům ze všech koutů naší země. Je to v podstatě hra pro dva, ale kdo je ten druhý, to určí hra samotná. Ale můžeš samozřejmě vyzvat svého kamaráda, když nechceš hrát proti náhodnému protihráči. Někdy se divím, jak může soupeř mít správně všechny otázky, to mi připadá jak kdybych hrát proti skillnutému účastníkovi AZ kvízu.
  </div>

  <div class="story-image">
    <img src="assets/images/avatars/avatar8.svg" loading="lazy" alt="avatar 8 online kvíz">
  </div>
</div>

<!-- 9. story -->
<div class="story-wrapper">
  <div class="story-image">
    <img src="assets/images/avatars/avatar9.svg" loading="lazy" alt="avatar 9 online kvíz">
  </div>

  <div class="story-content bgc1">
    Hra pro všechny milovníky vědomostních her obohacená o interaktivitu. Asi takto bych tuto hru popsal. U nás hrajeme skoro celá rodina, protože v podstatě všichni luštíme křížovky a sledujeme vědomostí pořady v televizi. Ze začátku mě zmátlo, když mi soupeři posílali takové divné zprávy a já nevěděl, co to má znamenat, a až pak jsem zjistil, že to byly náhodně vygenerované škodící zprávy, které musím říct opravdu zaškodily, protože jsem se nevěnoval pozorně otázce, ale četl jsem jakousi zprávu o Davidovi a Goliášovi. Až pak jsem zjistil, že pro posílání těchto zpráv musím být zaregistrovaný a díky tomu pak můžu soupeřit o prvenství v Síni slávy.  Ale líbí se mi, že kdo si chce tento online kvíz jen zahrát, si ho může zahrát jako anonymní uživatel a nemusí být registrovaný. Nesbírá sice body a nemůže posílat škodící a láskyplné zprávy a tipovat špatné odpovědi soupeře, ale jinak je hra naprosto stejná a nemá žádné nevýhody, takže skvělé pro ty, co si chtějí jen tak občas zahrát a nechtějí se všude registrovat.
  </div>
</div>

</div>
