<div class="wrapper">


  <mat-card>
    <mat-card-header>
      <mat-card-title>Poděkování</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>
        <a href="https://loading.io/icon">Thanks for icons, loading.io</a>
      </p>
    </mat-card-content>

  </mat-card>

</div>


