import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';
// FOR SPECIFIC TOOLBAR ON HOMEPAGE
// import { Router, NavigationEnd, ResolveEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  isAdmin = false;
  private authListenerSubs: Subscription;
  username: string;
  url: string;

  // FOR SPECIFIC TOOLBAR ON HOMEPAGE
  /* constructor(private authService: AuthService, private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  } */

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    if (this.userIsAuthenticated) {
      this.isAdmin = this.authService.getIsAdmin();
    }
    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
      if (this.userIsAuthenticated) {
        this.isAdmin = this.authService.getIsAdmin();
      }
    });

  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }

  getUsername() {
    return this.authService.getUsername();
  }

  onLogout() {
    this.authService.logout();
  }

}
