<div class="wrapper">


  <mat-card>
    <mat-card-header>
      <mat-card-title>Obecné</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <ul>
        <li>
          Heslo zadané uživatelem při registraci se šifruje a ukládá do databáze v této šifrované podobě.
        </li>
        <li>Email uvedený uživatelem v registraci se nikdy neprodává ani nijak neposkytuje třetí straně pro marketingové ani jiné účely.
        </li>

      </ul>
    </mat-card-content>

  </mat-card>

</div>

