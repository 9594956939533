<div class="wrapper">

  <mat-card class="card-wrapper">
    <mat-card-header>
      <div mat-card-avatar> <mat-icon>games</mat-icon></div>
      <mat-card-title>CHAT</mat-card-title>
      <mat-card-subtitle>Pro rychlý pokec se soupeřem ✍ a případnou gratulaci 🤝</mat-card-subtitle>
    </mat-card-header>


      <div #container class="message-box" [ngClass]="{
        'long-message-box':expandMessageBox === true
      }">

            <div *ngFor="let message of messages" class="message">
              <div *ngIf="message.name" class="chat-name" [ngClass]="{
                'message-name-left':message.name === myPlayerName,
                'message-name-right':message.name === opponentName,
                'message-name-center':message.name !== myPlayerName && message.name !== opponentName
              }">
                {{message.name}}
              </div>
              <div *ngIf="message.name === myPlayerName || message.name === opponentName" [ngClass]="{
                'message-left':message.name === myPlayerName,
                'message-right':message.name === opponentName,
                'message-center':message.name !== myPlayerName && message.name !== opponentName
              }">
                {{message.message}}
              </div>
              <p *ngIf="message.name !== myPlayerName && message.name !== opponentName" class="message-center">
                {{message.message}}
              </p>
            </div>

      </div>

    <mat-card-actions>
      <form [formGroup]="chatForm" (submit)="sendChatMessage()" autocomplete="off">
        <mat-form-field class="full-width">
          <input matInput formControlName="message" type="text" placeholder="">
          <mat-icon matSuffix *ngIf="!leftTheChat" (click)="sendChatMessage()" class="icon-send">send</mat-icon>
        </mat-form-field>

      </form>
    </mat-card-actions>

  </mat-card>


</div>
