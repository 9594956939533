<div class="container">

  <!-- FOR CASES WITHOUT SAME HEADER ON HOMEPAGE -->
  <!-- <mat-toolbar color="primary" *ngIf="url!=='/'"> -->
 <div class="header-main">   
 <mat-toolbar color="primary" class="header">
  <div fxShow="true" fxHide.gt-sm="true">
    <span (click)="sidenav.toggle()" class="pointer"><mat-icon>menu</mat-icon></span>
  </div>

  <span routerLink="/" class="homepage">Online KVÍZ</span>

    <span class="fill-remaining-space"></span>

    <div fxShow="true" fxHide.lt-md="true">
      <!-- The following menu items will be hidden on both SM and XS screen sizes -->
      <button mat-button routerLink="/sin-slavy">Síň slávy</button>
      <button mat-button routerLink="/hrej">Hrej</button>
      <button *ngIf="!userIsAuthenticated" mat-button routerLink="/prihlaseni">Přihlášení</button>
      <button *ngIf="!userIsAuthenticated" mat-raised-button color="accent" routerLink="/registrace">Registrace</button>
    </div>
    <button *ngIf="userIsAuthenticated" mat-button [matMenuTriggerFor]="menu"><mat-icon>account_circle</mat-icon><span fxHide.lt-md="true">{{ getUsername() }}</span></button>
      <mat-menu #menu="matMenu" yPosition="below">
        <div class="usermenu">Uživatelské menu</div>
        <hr />
        <button mat-menu-item routerLink="/hry/{{getUsername()}}">Moje hry</button>
        <button mat-menu-item (click)="onLogout()">Odhlásit</button>
      </mat-menu>


  </mat-toolbar>
</div>


  <mat-sidenav-container class="main-container" autosize>
    <mat-sidenav #sidenav mode="over" opened="false" fxHide.gt-sm="true" class="left-sidenav">

        <mat-nav-list>
          <a mat-list-item routerLink="/" class="hide"></a>
          <mat-list-item routerLink="/"  (click)="sidenav.toggle()" routerLinkActive="active-link">
            <mat-icon>dashboard</mat-icon>
            <div>Homepage</div>
          </mat-list-item>
          <a mat-list-item routerLink="/hrej"  (click)="sidenav.toggle()" routerLinkActive="active-link">
            <mat-icon>play_arrow</mat-icon>
            <div>Hrej</div>
          </a>
          <a mat-list-item routerLink="/sin-slavy" (click)="sidenav.toggle()" routerLinkActive="active-link">
            <mat-icon>emoji_events</mat-icon>
            <div>Síň slávy</div>
          </a>
          <a *ngIf="isAdmin" mat-list-item routerLink="/admin-list" (click)="sidenav.toggle()" routerLinkActive="active-link">
            <mat-icon>library_books</mat-icon>
            <div>Otázky (Admin)</div>
          </a>
          <a *ngIf="isAdmin" mat-list-item routerLink="/admin" (click)="sidenav.toggle()" routerLinkActive="active-link">
            <mat-icon>location_on</mat-icon>
            <div>Nová otázka (Admin)</div>
          </a>
          <a mat-list-item routerLink="/kontakt" (click)="sidenav.toggle()" routerLinkActive="active-link">
            <mat-icon>calendar_today</mat-icon>
            <div>Kontakt</div>
          </a>
        </mat-nav-list>

        <!--
        <div class="vertical"><a class="sidenav" href="#" ><mat-icon class="material">play_circle_filled</mat-icon><span> Hrej</span></a></div>
        <span><a class="sidenav" href="#" ><mat-icon>account_box</mat-icon> Registrace</a></span>
        <span><a class="sidenav" href="#" ><mat-icon>account_circle</mat-icon> Přihlášení</a></span>

      -->

    </mat-sidenav>
    <!--fxFlexFill not needed -->
    <mat-sidenav-content fxLayout="column" class="main-sidenav">

      <main class="content">
        <router-outlet></router-outlet>
      </main>

      <footer fxFlexOffset="auto" class="footer">
        <app-footer></app-footer>
      </footer>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>



