import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlayService } from 'src/app/services/play/play.service';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit, OnDestroy {

  @Input() inputTime: number;
  FULL_DASH_ARRAY = 283;
  WARNING_THRESHOLD = 10;
  ALERT_THRESHOLD = 5;
  TIME_LIMIT: number;
  time: number;
  interval: any;
  timePassed = 0 ;
  timeLeft: number;
  circleDasharray: string;
  color = '#0ae20a';

  playSubs: Subscription;

  constructor(private playService: PlayService) { }

  ngOnInit(): void {
    this.TIME_LIMIT = this.inputTime;
    this.time = this.inputTime;
    this.timeLeft = this.inputTime;

    this.start();

    this.playSubs = this.playService.getPlayListener().subscribe(data => {
      if (data.type === 'start-counter') {
        this.start();
      }

      if (data.type === 'init-counter') {
        if (data.round !== 1) {
          this.initGame();
        }
      }

      if (data.type === 'stop-counter') {
        clearInterval(this.interval);
      }
    });

  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.playSubs.unsubscribe();
  }

  start() {
    this.setCircleDasharray();
    this.interval = setInterval(() => {
      this.time = this.time - 1;
      this.timePassed = this.timePassed + 1;
      this.timeLeft = this.TIME_LIMIT - this.timePassed;

      if (this.time > 0) {
        this.setCircleDasharray();
        this.setColor();
      }

      if (this.time === 0) {
        clearInterval(this.interval);
        // this.timeLeft=this.TIME_LIMIT;
        // this.circleDasharray = '283 283';
        this.playService.submitAnswer();
      }
    }, 1000);
  }

  calculateTimeFraction() {
    // JUST FOR DEBUGGING console.log('timeleft: ' + this.timeLeft + '    TIME LIMIT: ' + this.TIME_LIMIT);
    const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
    // JUST FOR DEBUGGING console.log('rawTimeFraction: ' + rawTimeFraction);
    return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
  }

  setCircleDasharray() {
    this.circleDasharray = `${(
      this.calculateTimeFraction() * this.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    // JUST FOR DEBUGGING console.log(this.circleDasharray);
  }

  setColor() {
    if (this.time > (this.TIME_LIMIT / 2) ) {
      this.color = '#0ae20a';
    }

    if (this.time > (this.TIME_LIMIT / 4) && this.time <= (this.TIME_LIMIT / 2) ) {
      this.color = 'orange';
    }

    if (this.time <= (this.TIME_LIMIT / 4) ) {
      this.color = 'red';
    }
  }

  initGame() {
    this.time = this.TIME_LIMIT;
    this.timePassed = 0 ;
    this.timeLeft = this.TIME_LIMIT;
    this.start();
  }



}
