import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs';
import { Results } from 'src/app/models/gameResults';
import { MessagesService } from '../messages/messages.service';

const BACKEND_URL = environment.url;

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  private socket: SocketIOClient.Socket;
  private opponent: string;
  private question: object;
  private player1: string;
  private player1rating: number;
  private player2: string;
  private player2rating: number;
  private questionListener = new Subject<any>();
  private chatListener = new Subject<any>();

  constructor(private authService: AuthService, private messageService: MessagesService) {
  }

  close() {
    this.socket.disconnect();
  }

  initSocket() {
    this.socket = io.connect(BACKEND_URL, {path: '/app/socket.io'});
  }

  resetGame() {
    this.opponent = null;
    this.player1 = null;
    this.player2 = null;
  }

  launchRematchGame(playerName: string) {
    this.socket.emit('joinroom', {user: playerName});
  }

  launchGame() {
    this.socket.emit('joinroom', {user: this.authService.getUsername()});
    this.listenSocketEvents();
  }

  launchGameWithBot(playerName: string) {
    this.socket.emit('launchGameWithBot', playerName);
  }

  launchAnonymousGame(playerName: string) {
    this.socket.emit('joinroom', {user: playerName});
    this.listenSocketEvents();
  }

  joinGame(playerName: string, opponent: string) {
    this.socket.emit('joinGame', {user: playerName, opponent: opponent});
    this.listenSocketEvents();
  }

  createGame(playerName: string) {
    this.socket.emit('createGame', {user: playerName});
    console.log('createGame event started for user: ' + playerName);
    this.listenSocketEvents();
  }

  suggestRematch(playerName: string) {
    this.socket.emit('suggestRematch', {user: playerName});
  }

  joinRematchGame() {
    this.socket.emit('joinRematch');
  }

  rejectRematch(playerName: string) {
    this.socket.emit('rejectRematch', {user: playerName});
  }

  listenSocketEvents() {
    this.socket.on('message', message => {
    });

    this.socket.on('opponent', opponent => {
      this.opponent = opponent.opponent;
      this.player1 = opponent.player1;
      this.player2 = opponent.player2;
      this.player1rating = opponent.player1rating;
      this.player2rating = opponent.player2rating;
      this.questionListener.next({type: 'players', opponent: this.opponent, player1: this.player1, player1rating: this.player1rating,
                                  player2: this.player2, player2rating: this.player2rating});
    });

    this.socket.on('question', question => {
      /* JUST FOR DEBUGGING
      console.log('received question');
      console.log(question); */
      this.playNewRoundSound();
      this.question = question;
      this.questionListener.next({type: 'question', question: question});
    });

    this.socket.on('saveOpponentAndGameIdOnSocket', object => {
      this.socket.emit('saveOpponentAndGameIdOnSocket', object);
    });

    this.socket.on('finishGame', (results: Results) => {
      /* JUST FOR DEBUGGING
      console.log(results); */
      this.playFinishSound();
      this.questionListener.next({type: 'results', results});
    });

    this.socket.on('opponentHasAnswered', round => {
      this.questionListener.next({type: 'opponentAnswered', round: round});
      this.messageService.openSnackBar(this.opponent + ': právě odpověděl(a) na otázku ' + round + '. kola', 'Zavři', 2000);
    });

    this.socket.on('terrifyingMessage', message => {
      this.messageService.openSnackBar(this.opponent + ': ' + message, 'Zavři', 5000, ['hotpink']);
      this.playNewMessageSound();
    });

    this.socket.on('loveMessage', message => {
      this.messageService.openSnackBar(this.opponent + ': ' + message, 'Zavři', 5000, ['hotpink']);
      this.playNewMessageSound();
    });

    this.socket.on('guessWrongAnswer', round => {
      this.messageService.openSnackBar(this.opponent + ': tipuje, že tuhle otázku zodpovíš špatně', 'Zavři', 2000, ['hotpink']);
      this.playNewMessageSound();
    });

    this.socket.on('suggestRematch', user => {
      /* JUST FOR DEBUGGING
      console.log('inside suggestedRematch. User: ' + user.user); */
      this.questionListener.next({type: 'rematch', user: user.user});
      this.playNewMessageSound();
    });

    this.socket.on('rejectedRematch', () => {
      this.questionListener.next({type: 'rejectedRematch'});
    });

    this.socket.on('disconnectedOpponent', opponent => {
      this.messageService.openSnackBar(opponent + ' už opustil hru.', 'Zavři', 3000);
      this.questionListener.next({type: 'disconnectedOpponent'});
    });

    this.socket.on('disconnectedGame', opponent => {
      this.messageService.openSnackBar(opponent + ' opustil(a) hru. Vyhráváš kontumačně', 'Zavři', 3000);
    });

    this.socket.on('botreply', data => {
      this.messageService.openSnackBar(data.bot + ': ' + data.message, 'Zavři', 3000, ['hotpink']);
      this.playNewMessageSound();
    });

    this.socket.on('chatMessage', message => {
      this.chatListener.next({type: 'message', message: message});
      this.playNewMessageSound();
    });

    this.socket.on('leftTheChat', message => {
      this.chatListener.next({type: 'leftTheChat'});
    });


    this.socket.on('resetGame', () => {
      this.questionListener.next({type: 'resetGame'});
    });
  }

  confirmAnswer(answer: string) {
    /* JUST FOR DEBUGGING
    console.log('emitting answer from service'); */
    this.socket.emit('confirmAnswer', answer);
  }

  playNewMessageSound() {
    const audio = new Audio();
    audio.src = 'assets/sounds/new-message.wav';
    audio.load();
    audio.play();
  }

  playFinishSound() {
    const audio = new Audio();
    audio.src = 'assets/sounds/score-counter.wav';
    audio.load();
    audio.play();
  }

  playNewRoundSound() {
    const audio = new Audio();
    audio.src = 'assets/sounds/round.wav';
    audio.load();
    audio.play();
  }

  sendTerrifyingMessage(message: string) {
    this.socket.emit('terrifyingMessage', message);
  }

  sendLoveMessage(message: string) {
    this.socket.emit('loveMessage', message);
  }

  sendWrongAnswerGuess() {
    this.socket.emit('wrongAnswerGuess');
  }

  guessWrongAnswer() {
    this.socket.emit('guessWrongAnswer');
  }

  getQuestionListener() {
    return this.questionListener.asObservable();
  }

  getChatListener() {
    return this.chatListener.asObservable();
  }

  sendChatMessage(message: string) {
    console.log('sending chat message to backend');
    this.socket.emit('chatMessage', message);
  }

  leftTheChat() {
    this.socket.emit('leftTheChat', '');
  }
}
