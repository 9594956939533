
<!--  template driven approach
<mat-card>
  <form (submit)="onSignup(signupForm)" #signupForm="ngForm">
    <mat-form-field>
      <input matInput name="email" type="email"  ngModel placeholder="E-Mail" #emailInput="ngModel" required autocomplete="username">
      <mat-error *ngIf="emailInput.invalid">Please enter a valid email.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput name="password" type="password" ngModel placeholder="Password" #passwordInput="ngModel" required  autocomplete="new-password">
      <mat-error *ngIf="passwordInput.invalid">Please enter a valid password.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input (keyup)="onChange(signupForm)" matInput name="passwordRetype" type="password" ngModel required placeholder="RE-type Password" #passwordRetypeInput="ngModel"  autocomplete="new-password">
      <span class="error" *ngIf="!isSamePassword && passwordRetypeInput.touched"> Passwords are not same.</span>
    </mat-form-field>

    <button mat-raised-button color="accent" type="submit">Registrovat</button>
  </form>
</mat-card>
-->

<mat-card class="auth-card">
  <mat-card-header>
    <mat-card-title>Registrace</mat-card-title>
    <mat-card-subtitle>bude to hned !!</mat-card-subtitle>
  </mat-card-header>

  <div class="image">
    <img src="assets/images/icons/form.svg">
  </div>

  <form [formGroup]="form" (submit)="onSubmit()" >

    <mat-form-field>
      <input matInput formControlName="email" type="email" autocomplete="email" placeholder="E-Mail">
      <mat-icon matSuffix>email</mat-icon>
      <mat-error *ngIf="form.get('email').invalid">{{getEmailError()}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="username" type="text" autocomplete="nickname" placeholder="Uživatelské jméno">
      <mat-icon matSuffix>account_circle</mat-icon>
      <mat-error *ngIf="form.get('username').invalid">{{getUsernameError()}}</mat-error>
      <!-- <mat-error *ngIf="form.controls.username.errors.required">Je to povinne</mat-error>
      <mat-error *ngIf="form.controls.username.errors.minlength">Alespon 3 znaky</mat-error> -->
    </mat-form-field>


    <mat-form-field>
      <input matInput formControlName="password" type="password" autocomplete="new-password" placeholder="Heslo">
      <mat-icon matSuffix>vpn_key</mat-icon>
      <mat-error *ngIf="form.get('password').invalid">{{getPasswordError()}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="confirmedPassword" type="password" autocomplete="new-password" placeholder="Heslo znovu">
      <mat-icon matSuffix>vpn_key</mat-icon>
      <mat-error *ngIf="form.get('confirmedPassword').invalid">{{getConfirmedPasswordError()}}</mat-error>
    </mat-form-field>

    <button mat-raised-button color="accent" type="submit">Registrovat</button>

  </form>
</mat-card>
