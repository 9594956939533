<footer>
<div class="wrapper">

  <div class="footer-item">
    <div>O hře</div>
    <ul>
      <li><a routerLink="/pravidla">Pravidla</a></li>
      <li><a routerLink="/skore">Skóre</a></li>
    </ul>

  </div>

  <div class="footer-item">
    <div>Obecné</div>
    <ul>
      <li><a routerLink="/general">Zacházení s daty</a></li>
      <li><a routerLink="/kontakt">Kontakt</a></li>
      <li><a routerLink="/diky">Poděkování</a></li>
    </ul>
  </div>


  <div class="footer-item">
    <div class="title">Vytvořeno pomocí</div>
    <div class="c3">
      <div class="tech-img"><img src="assets/images/footer/angular_logo.svg" alt="angular"></div>
      <div class="tech-img"><img src="assets/images/footer/node_logo.svg" alt="node.js"></div>
      <div class="tech-img"><img src="assets/images/footer/socket-logo.svg" alt="socket.io"></div>
    </div>
  </div>

</div>
</footer>
