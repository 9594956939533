<div class="wrapper">


  <mat-card>
    <mat-card-header>
      <mat-card-title>SKÓRE</mat-card-title>
      <mat-card-subtitle>Aneb jak se to počítá...</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div>
        Zajímá-li tě jakým způsobem se počítá o kolik se zvýší/sníží tvé skóre při hře proti soupeři, tady je popis. Obecně však platí, že pokud vyhraješ nad hráčem
        s mnohem vyšším skóre než je tvoje, tím více se zvýší i tvé skóre. Pokud naopak prohraješ proti soupeři s mnohem menším skóre, tvé skóre se tím více sníží. V případě, kdy
        hraješ proti soupeři s podobným skóre jako je tvoje, obecně platí že výhra nebo prohra znamená posun ve skóre o +20 / -20
      </div>
      <ul>
        <li>Pokud hraješ jako neregistrovaný hráč (tedy tvé hrací jméno začíná anonymous_) , tak se tě počítání skóre vůbec netýká.
        </li>
        <li>Pokud jsi registrovaný a hraješ proti neregistrovanému (např. anonymous_Petr ) hráči, v případě výhry získáváš 15 bodů ke tvému skóre. Pokud prohraješ, tvé skóre se sníží
            o 15 bodů.
        </li>
        <li>
          Pokud je tvé skóre vyšší než soupeřovo a podaří se ti vyhrát, získáš 20 bodů ke tvému skóre.
        </li>
        <li>
          Pokud je tvé skóre vyšší než soupeřovo a prohraješ, skóre se ti sníží podle vzorce (dále mluveno jako o vzorci X) Math.ceil(rozdíl ve skóre v absolutním čísle / 100 ) * 20 <br/>
          Math.ceil - vrátí číselnou hodnotu (integer) větší nebo rovnou hodnotě argumentu - jako argument je bráno výsledek dělení v závorce <br/>
          Příklady: <br/>
          Rozdíl ve skóre 1 - 100 znamená snížení skóre o 20.   <br/>
          Rozdíl ve skóre 101 - 200 znamená snížení skóre o 40.   <br/>
          Rozdíl ve skóre 401 - 500 znamená snížení skóre o 100.   <br/>
        </li>
        <li>
          Pokud je tvé skóre nižší než soupeřovo a prohraješ, sníží se ti skóre o 20 bodů.
        </li>
        <li>
          Pokud je tvé skóre nižší než soupeřovo a podaří se ti vyhrát, skóre se ti zvýší podle vzorce Math.ceil(rozdíl ve skóre v absolutním čísle / 100 ) * 20 <br/>
          Math.ceil - vrátí číselnou hodnotu (integer) větší nebo rovnou hodnotě argumentu - jako argument je bráno výsledek dělení v závorce <br/>
          Příklady: <br/>
          Rozdíl ve skóre 1 - 100 znamená zvýšení skóre o 20.   <br/>
          Rozdíl ve skóre 101 - 200 znamená zvýšení skóre o 40.   <br/>
          Rozdíl ve skóre 401 - 500 znamená zvýšení skóre o 100.   <br/>
        </li>
        <li>
          Pokud je tvé skóre nižší než soupeřovo a remizuješ, skóre se ti zvýší o zaokrouhlený výsledek vzorce X / 10
        </li>
        <li>
          Pokud je tvé skóre vyšší než soupeřovo a remizuješ, skóre se ti sníží o zaokrouhlený výsledek vzorce X / 10
        </li>
        <li>
          V případě, kdy remizuješ proti neregistrovanému hráči, se ti skóre sníží o 2 body.
        </li>
      </ul>
    </mat-card-content>

  </mat-card>

</div>
