import { Component, OnInit } from '@angular/core';
import { GamesService } from 'src/app/services/games/games.service';
import { Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-toplist',
  templateUrl: './toplist.component.html',
  styleUrls: ['./toplist.component.css']
})
export class ToplistComponent implements OnInit {

  toplistSub: Subscription;
  gamesCount: number;
  players: {username: string, rating: number}[];
  displayedColumns = ['index', 'username', 'rating'];

  constructor(private gamesService: GamesService, private titleService: Title, private metaTagService: Meta) { 
  }

  ngOnInit(): void {
    this.titleService.setTitle('Síň slávy');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Bájná Síň slávy, kde dostat se je uměním nejvyšším v porážení ostatních hráčů v online kvízu'});

    this.toplistSub = this.gamesService.getTopPlayers().subscribe(players => {
      this.players = players;
    });

    this.gamesService.getGamesCount().subscribe(count => {
      this.gamesCount = count.count;
    });
  }

  getSymbol(index: number) {
    let text = '';
    switch (index) {
      case 1: {
        text = '   🥇';
        break;
      }
      case 2: {
        text = '   🥈';
        break;
      }
      case 3: {
        text = '   🥉';
        break;
      }
      case 4: {
        text = '   🥔';
        break;
      }
      case 5: {
        text = '';
        break;
      }
      default: {
        text = '';
      }
    }
    return text;
  }
}
