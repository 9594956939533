<div class="wrapper">

<mat-card class="auth-card">
  <mat-card-header>
    <mat-card-title>Přihlášení</mat-card-title>
    <mat-card-subtitle>bude to hned !!</mat-card-subtitle>
  </mat-card-header>

  <div class="image">
    <img src="assets/images/icons/padlock.svg">
  </div>

  <form [formGroup]="loginForm" (submit)="onSubmit()" >

    <mat-form-field>
      <input matInput formControlName="email" type="email" autocomplete="email" placeholder="E-Mail">
      <mat-icon matSuffix>email</mat-icon>
      <mat-error *ngIf="loginForm.get('email').invalid">{{getEmailError()}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput formControlName="password" type="password" autocomplete="new-password" placeholder="Heslo">
      <mat-icon matSuffix>vpn_key</mat-icon>
      <mat-error *ngIf="loginForm.get('password').invalid">{{getPasswordError()}}</mat-error>
    </mat-form-field>


    <button mat-raised-button class="primary" type="submit">Přihlásit</button>

  </form>
</mat-card>
</div>
