<div class="wrapper">
  <mat-card *ngFor="let question of results.questions; let i = index">
    <mat-card-header>
      <mat-card-title> {{i + 1}}. KOLO</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <p>
        {{question.question}}
      </p>
      <img mat-card-image [src]="question.imagePath" class="question-image">
      <p>Správná odpověď: {{question.correctAnswer}}</p>
      <hr>
      <p class="center">Odpovědi: 🙋</p>
      <span class="player1">{{results.player1.name}}</span>: {{results.player1.answers[i].answer}}<br/>
      <span class="player2">{{results.player2.name}}</span>: {{results.player2.answers[i].answer}}<br/>
      <hr>
      <p class="center">Body: 🏆</p>
      <span class="player1">{{results.player1.name}}:</span> {{results.player1.answers[i].points}}<br/>
      <span class="player2">{{results.player2.name}}:</span> {{results.player2.answers[i].points}}<br/>
      <div *ngIf="results.player1.answers[i].additionalPoints.length > 0 || results.player2.answers[i].additionalPoints.length > 0">
        <hr>
        <p class="center">Z toho doplňkové body: 💪</p>

        <div *ngIf="results.player1.answers[i].additionalPoints.length > 0">
          <span class="player1">{{results.player1.name}}:</span> <br/>
          <div *ngFor="let additional of results.player1.answers[i].additionalPoints">
            <span>Typ: </span> {{getType(additional.additionalType)}} <br/>
            <span>Body: </span> {{additional.points}} <br/>
          </div>
        </div>

        <div *ngIf="results.player2.answers[i].additionalPoints.length > 0">
          <span class="player2">{{results.player2.name}}:</span> <br/>
          <div *ngFor="let additional of results.player2.answers[i].additionalPoints">
            <span>Typ: </span> {{getType(additional.additionalType)}} <br/>
            <span>Body: </span> {{additional.points}} <br/>
          </div>
        </div>


      </div>
    </mat-card-content>

  </mat-card>

</div>
