import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { IntroComponent } from './components/intro/intro.component';
import { PlayComponent } from './components/play/play.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminListComponent } from './components/admin-list/admin-list.component';
import { SignupComponent } from './components/auth/signup/signup.component';
import { LoginComponent } from './components/auth/login/login.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ResultsComponent } from './components/game/results/results.component';
import { RematchDialogComponent } from './components/rematch-dialog/rematch-dialog.component';
import { GamesComponent } from './components/user/games/games.component';
import { getCzechPaginatorIntl } from 'src/intl/czech-paginator-intl';
import { ResultPipe } from './pipes/result.pipe';
import { ToplistComponent } from './components/user/toplist/toplist/toplist.component';
import { ChatComponent } from './components/game/chat/chat/chat.component';
import { FooterComponent } from './components/footer/footer.component';
import { CounterComponent } from './components/game/counter/counter.component';
import { RulesComponent } from './components/about/rules/rules.component';
import { RatingComponent } from './components/about/rating/rating.component';
import { GeneralComponent } from './components/about/general/general.component';
import { ContactComponent } from './components/about/contact/contact.component';
import { ThanksComponent } from './components/about/thanks/thanks.component';
import { LatestGamesComponent } from './components/games/latest-games/latest-games.component';
import { SocialComponent } from './components/common/social/social.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    IntroComponent,
    PlayComponent,
    AdminComponent,
    AdminListComponent,
    SignupComponent,
    LoginComponent,
    MessagesComponent,
    ResultsComponent,
    RematchDialogComponent,
    GamesComponent,
    ResultPipe,
    ToplistComponent,
    ChatComponent,
    FooterComponent,
    CounterComponent,
    RulesComponent,
    RatingComponent,
    GeneralComponent,
    ContactComponent,
    ThanksComponent,
    LatestGamesComponent,
    SocialComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatRadioModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatChipsModule,
    MatDividerModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTableModule,
    FlexLayoutModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getCzechPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
