import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { SocketService } from 'src/app/services/socket/socket.service';
import { Question } from 'src/app/models/gameQuestion';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Results } from 'src/app/models/gameResults';
import { MessagesService } from 'src/app/services/messages/messages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RematchDialogComponent } from '../rematch-dialog/rematch-dialog.component';
import { PlayService } from 'src/app/services/play/play.service';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.css']
})

export class PlayComponent implements OnInit, OnDestroy {

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  mode = 'create';
  expandCreateGame = false;  //when changed to true, option for create own game is shown - to play against friend
  anonymousForm: FormGroup;
  question: Question = null;
  answers: string[];
  isGameLaunched = false;
  searchingForGame = false;
  isIntroDisplayed = true;
  isGameFinished = false;
  isWaitingForRematch = false;
  hasOpponentAnswered = false;
  gameQuestionSubs: Subscription;
  playerAnswer: string;
  hasAnswered = false;
  round = 0;
  isUserAuthenticated = false;
  private authListenerSubs: Subscription;
  private playSubs: Subscription;
  results: Results;
  url: string;
  gameResult: string;

  counterTime = 30; // time for app-counter component - limit for questions

  botInterval: any; //interval for counting of botCounter time
  botCounter: number = 5; //after this time in second should user get option to play against bot
  showBot = false;

  myPlayerName: string;
  opponentName: string;
  myRating: number;
  player1: string;
  player2: string;
  player1rating: number;
  player2rating: number;
  ratingDifference: number;
  ratingInterval: any;


  terrifyingMessages = ['BU BU BU, prohraješ, nemáš proti mně žádnou šanci 🤬',
  'Jsem Goliáš a ty jsi David - bez praku 🤣',
  'Rozdrtím tě na kousky jako skartovačka papír 😖',
  'Dokážu vyhrát i se zavřenýma očima',
  'Je mi jasné, že vyhraju',
  'Jsem velký myslitel, tvá porážka je nevyhnutelná',
  'Vydrž, prohra už klepe na dveře',
  'Můj pes právě vycítil, že tuhle hru vyhraji'
  ];
  loveMessages = ['Na světě je přeci krásně, proč proti sobě vlastně soupeřit? 💞',
  'Já vím, že ty víš, že se máme vzájemně rádi 😍',
  'Přeji srdíčkový den 💖💖💖',
  'Je úplně jedno, kdo vyhraje, hlavní je si zahrát',
  'Kopretiny jsou tak nádherné, co takhle jít ven?',
  'Hraje se mi s tebou pěkně'
  ];

  isTerrifiedClicked = false;
  isLoveClicked = false;
  isWrongClicked = false;

  constructor(public socketService: SocketService, private authService: AuthService, private messageService: MessagesService,
              private playService: PlayService, private snackBar: MatSnackBar, public dialog: MatDialog, public route: ActivatedRoute,
              private titleService: Title, private metaTagService: Meta, private cdr: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platfromId) { }


  ngOnInit() {
    this.titleService.setTitle('Kvízová hra dvou hráčů - HREJ');
    this.metaTagService.updateTag(
      { name: 'description', content: 'Prokaž své znalosti a hrej proti náhodnému hráči nebo vyzvi svého kamaráda. Sbírej body za výhry a dostaň se do síně slávy'});

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.mode = 'join';
        this.player1 = paramMap.get('id');
        /* JUST FOR DEBUGGING
        console.log('player1: ' + this.player1); */
      }
    });

    if (isPlatformBrowser(this.platfromId)) {
      this.url = location.href;
    }

    this.anonymousForm = new FormGroup({
      'playerName': new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(10)])
    });

    this.isUserAuthenticated = this.authService.getIsAuth();
    if (this.isUserAuthenticated) {
      this.myPlayerName = this.authService.getUsername();
      this.myRating = this.authService.getRating();
    }
    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe(isAuthenticated => {
      this.isUserAuthenticated = isAuthenticated;
      if (this.isUserAuthenticated) {
        this.myPlayerName = this.authService.getUsername();
      }
    });

    if (isPlatformBrowser(this.platfromId)) {
      this.socketService.initSocket();
    }

    this.playSubs = this.playService.getPlayInGameListener().subscribe(data => {
      if (data.type === 'submit-answer') {
        this.confirmAnswer(this.playerAnswer);
      }
    });

    this.gameQuestionSubs = this.socketService.getQuestionListener().subscribe(data => {
      if (data.type === 'question') {
        this.scrollTop();
        this.isGameLaunched = true;
        this.hasAnswered = false;
        this.hasOpponentAnswered = false;
        this.round = this.round + 1;
        this.playService.initCounter(this.round);
        this.playerAnswer = '';
        this.question = data.question;
      }

      if (data.type === 'players') {
        this.opponentName = data.opponent;
        this.player1 = data.player1;
        this.player2 = data.player2;
        this.player1rating = data.player1rating;
        this.player2rating = data.player2rating;
        /* JUST FOR DEBUGGING
        console.log(data); */
        clearInterval(this.botInterval);
      }

      if (data.type === 'results') {
        this.scrollTop();
        this.searchingForGame = false;
        this.isGameFinished = true;
        this.results = data.results;
        this.getRatingDifference();
      }

      if (data.type === 'rematch') {
        if (!this.isGameFinished) {
          this.socketService.rejectRematch(data.user);
        } else {
          const dialogRef = this.dialog.open(RematchDialogComponent, {
            width: '250px',
            data: {opponent: data.user}
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.socketService.joinRematchGame();
              this.resetGame();
            } else {
              this.socketService.rejectRematch(data.user);
            }
          });
        }
      }

      if (data.type === 'opponentAnswered') {
        this.hasOpponentAnswered = true;
      }

      if (data.type === 'rejectedRematch') {
        this.isWaitingForRematch = false;
        this.messageService.openSnackBar('Odveta byla odmítnuta', 'Zavři', 3000);
      }

      if (data.type === 'disconnectedOpponent') {
        this.isWaitingForRematch = false;
      }

      if (data.type === 'resetGame') {
        this.resetGame();
      }
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platfromId)) {
      this.socketService.close();
    }
    this.isGameLaunched = false;
    this.searchingForGame = false;
    this.playService.stopCounter();
    this.gameQuestionSubs.unsubscribe();
    this.authListenerSubs.unsubscribe();
    this.playSubs.unsubscribe();
  }

  scrollTop() {
    this.scrollContainer.nativeElement.scrollIntoView();
  }

  getQuestion() {
    return this.question;
  }

  getWordFinalResults() {
    if (this.results.player1.points === this.results.player2.points) {
      this.gameResult='draw';
      return 'REMÍZA 😏';
    }
    if (this.results.player1.points > this.results.player2.points) {
      if (this.player1 === this.myPlayerName) {
        this.gameResult='win';
        return 'VÝHRA 😎';
      } else {
        this.gameResult='loss';
        return 'PROHRA 😥';
      }
    } else {
      if (this.player2 === this.myPlayerName) {
        this.gameResult='win';
        return 'VÝHRA 😎';
      } else {
        this.gameResult='loss';
        return 'PROHRA 😥';
      }
    }
  }

  getPointsFinalResults() {
    return this.results.player1.points + ' : ' + this.results.player2.points;
  }

  getSignRatingDifference() {
    if (this.isUserAuthenticated && this.myPlayerName === this.results.player1.name) {
      if (this.results.player1.ratingDifference > 0) {
        return '+ ';
      }
      if (this.results.player1.ratingDifference < 0 ) {
        return '- ';
      }
    }

    if (this.isUserAuthenticated && this.myPlayerName === this.results.player2.name) {
      if (this.results.player2.ratingDifference > 0) {
        return '+ ';
      }
      if (this.results.player2.ratingDifference < 0 ) {
        return '- ';
      }
    }
  }

  getRatingDifference() {
    let difference = 0;
    if (this.isUserAuthenticated && this.myPlayerName === this.results.player1.name) {
      if (this.results.player1.ratingDifference > 0) {
        difference = this.results.player1.ratingDifference;
      }
      if (this.results.player1.ratingDifference < 0 ) {
        difference = Math.abs(this.results.player1.ratingDifference);
      } else {
        difference = this.results.player1.ratingDifference;
      }
    }

    if (this.isUserAuthenticated && this.myPlayerName === this.results.player2.name) {
      if (this.results.player2.ratingDifference > 0) {
        difference = this.results.player2.ratingDifference;
      }
      if (this.results.player2.ratingDifference < 0 ) {
        difference = Math.abs(this.results.player2.ratingDifference);
      } else {
        difference = this.results.player2.ratingDifference;
      }
    }

    let stepRatingInterval = 25;
    if (difference <= 50) {
      stepRatingInterval = 25;
    } else if (difference <= 100) {
      stepRatingInterval = 15;
    } else {
      stepRatingInterval = 7;
    }
    this.ratingDifference = 0;
    this.ratingInterval = setInterval(() => {
      if (this.ratingDifference >= difference) {
        clearInterval(this.ratingInterval);
      } else {
      this.ratingDifference = this.ratingDifference + 1;
      }
    }, stepRatingInterval);
  }

  createGame() {
    this.searchingForGame = true;
    this.isIntroDisplayed = false;
    this.socketService.createGame(this.myPlayerName);
  }

  launchGame() {
    this.searchingForGame = true;
    this.isIntroDisplayed = false;
    if (this.mode === 'create') {
      this.showBot = true;
      this.socketService.launchGame();
      // wait specific time to show option to play against bot
      this.botCounter = 5;
      this.botInterval = setInterval(() => {
        this.botCounter--;
        if (this.botCounter === 0) {
          clearInterval(this.botInterval);
        }
      }, 1000);
    }
    if (this.mode === 'join') {
      this.socketService.joinGame(this.myPlayerName, this.player1);
    }
  }

  launchGameWithBot() {
    this.searchingForGame = true;
    this.socketService.launchGameWithBot(this.myPlayerName);
  }

  resetGame() {
    this.mode = 'create';
    this.answers = [];
    this.isGameLaunched = false;
    this.searchingForGame = false;
    this.isGameFinished = false;
    this.isWaitingForRematch = false;
    this.hasAnswered = false;
    this.round = 0;
    this.isTerrifiedClicked = false;
    this.isLoveClicked = false;
    this.isWrongClicked = false;
    this.botCounter = 5;
    this.showBot = true;
  }

  suggestRematch() {
    this.isWaitingForRematch = true;
    this.socketService.suggestRematch(this.myPlayerName);
  }

  newGame() {
    this.resetGame();
    this.botInterval = setInterval(() => {
      this.botCounter--;
      if (this.botCounter === 0) {
        clearInterval(this.botInterval);
      }
    }, 1000);
    this.socketService.launchRematchGame(this.myPlayerName);
  }

  confirmAnswer(answer: string) {
    this.hasAnswered = true;
    this.socketService.confirmAnswer(answer);
    this.playService.stopCounter();
  }

  getPlayerNameError() {
    if (this.anonymousForm.get('playerName').hasError('required')) {
      return 'Zadej své jméno pro tuto hru';
    }
    if (this.anonymousForm.get('playerName').hasError('minlength')) {
        return 'Jméno musí mít alespoň 3 znaky';
    }
    if (this.anonymousForm.get('playerName').hasError('maxlength')) {
      return 'Jméno může mít maximálně 10 znaků';
    }
    if (this.anonymousForm.get('playerName').value.includes('hoj')) {
      return 'tohle ne';
    }
  }

  launchAnonymousGame() {
    if (this.anonymousForm.invalid) {
      return;
    }
    this.isIntroDisplayed = false;
    this.myPlayerName = 'anonymous_'.concat(this.anonymousForm.value.playerName);
    if (this.mode === 'create') {
      this.showBot = true;
      this.socketService.launchAnonymousGame('anonymous_'.concat(this.anonymousForm.value.playerName));
      // wait specific time to show option to play against bot
      this.botCounter = 5;
      this.botInterval = setInterval(() => {
        this.botCounter--;
        if (this.botCounter === 0) {
          clearInterval(this.botInterval);
        }
      }, 1000);
    }
    if (this.mode === 'join') {
      this.socketService.joinGame('anonymous_'.concat(this.anonymousForm.value.playerName), this.player1);
    }
  }

  sendTerrifyingMessage() {
    this.isTerrifiedClicked = true;
    const message = this.terrifyingMessages[Math.floor(Math.random() * this.terrifyingMessages.length)];
    this.messageService.openSnackBar(this.myPlayerName + ': ' + message, 'Zavři', 5000, ['hotpink']);
    this.socketService.sendTerrifyingMessage(message);
  }

  sendLoveMessage() {
    this.isLoveClicked = true;
    const message = this.loveMessages[Math.floor(Math.random() * this.loveMessages.length)];
    this.messageService.openSnackBar(this.myPlayerName + ': ' + message, 'Zavři', 5000, ['hotpink']);
    this.socketService.sendLoveMessage(message);
  }

  guessWrongAnswer() {
    this.isWrongClicked = true;
    this.messageService.openSnackBar(this.myPlayerName + ': tipuje, že soupeř odpoví tuhle otázku špatně', 'Zavři', 2000, ['hotpink']);
    this.socketService.guessWrongAnswer();
  }

  openSnackBar(message: string, button: string, duration: number) {
    this.snackBar.open(message, button, {
      duration: duration,
      panelClass: ['hotpink']
    });
  }

}
