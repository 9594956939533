import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthData } from 'src/app/models/auth/auth-data';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MessagesService } from '../messages/messages.service';

const BACKEND_URL = environment.apiUrl + '/user/';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authStatusListener = new Subject<boolean>();
  private loginErrorListener = new Subject<any>();
  private tokenTimer: any;
  private isAuthenticated = false;
  private token: string;
  private userId: string;
  private username: string;
  private rating: number;
  private loginErrorMessage: string;

  constructor(private http: HttpClient, private router: Router, private messagesService: MessagesService) { }

  createUser(email: string, username: string, password: string) {
    const authData: AuthData = {email: email, username: username, password: password};
    return this.http.post(BACKEND_URL + "signup", authData);
  }

  loginUser(email: string, password: string) {
    this.http.post<{ token: string; expiresIn: number; userId: string; username: string; rating?: number }>
    (BACKEND_URL + "login", {email: email, password: password})
    .subscribe(response => {
      const token = response.token;
      const username = response.username;
      const rating = response.rating;
      this.token = token;
      this.username = username;
      this.rating = rating;
      if (token) {
        const expiresInDuration = response.expiresIn;
        this.setAuthTimer(expiresInDuration);
        this.isAuthenticated = true;
        this.userId = response.userId;
        this.authStatusListener.next(true);
        const now = new Date();
        const expirationDate = new Date(
          now.getTime() + expiresInDuration * 1000
        );
        this.saveAuthData(token, expirationDate, this.userId, username);
        this.router.navigate(['/']);

      }

    }, error => {
      if (error.error.message) {
        this.loginErrorListener.next(error.error.message);
      }
    });
  }

  getLoginErrorListener() {
    return this.loginErrorListener.asObservable();
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getIsAdmin() {
    if (this.getUsername() === 'richard') {
      return true;
    } else {
      return false;
    }
  }

  getUsername() {
    if (this.username == null) { return "unknown"; }
    return this.username;
  }

  getRating() {
    return this.rating;
  }

  getToken() {
    return this.token;
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if ( expiresIn > 0 ) {
      this.http.get<{rating: number}>(BACKEND_URL + 'rating/' + authInformation.username).subscribe(data => {
        this.rating = data.rating;
      });
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.username = authInformation.username;
      this.authStatusListener.next(true);
    }


  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    const username = localStorage.getItem('username');
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      username: username
    };

  }

  private saveAuthData(token: string, expirationDate: Date, userId: string, username: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);

  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  private setAuthTimer(duration: number) {
    /* JUST FOR DEBUGGING PURPOSES
    console.log('Setting timer: ' + duration); */
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = null;
    this.username = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/']);


  }
  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
  }

  navigateToLogin() {
    this.router.navigate(['/prihlaseni']);
  }

}






