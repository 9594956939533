<mat-expansion-panel *ngFor="let question of questions">
  <mat-expansion-panel-header>
    {{ question.category }} - {{ question.question }}
  </mat-expansion-panel-header>
  <div class="image">
    <img [src]="question.imagePath" loading="lazy">
  </div>
  <div>
    Correct: {{ question.answers.correct }}
    Wrong: {{ question.answers.wrong }}
  </div>
  <button mat-button color="warn" (click)="delete(question._id)">DELETE</button>
  <a mat-button color="primary" [routerLink]="['/admin-list-edit', question._id]">EDIT</a>
</mat-expansion-panel>
<mat-paginator [length]="totalQuestions" [pageSize]="questionsPerPage" [pageSizeOptions]="pageSizeOptions" (page)="onChangedPage($event)"></mat-paginator>
