import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './components/intro/intro.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminListComponent } from './components/admin-list/admin-list.component';
import { SignupComponent } from './components/auth/signup/signup.component';
import { LoginComponent } from './components/auth/login/login.component';
import { PlayComponent } from './components/play/play.component';
import { GamesComponent } from './components/user/games/games.component';
import { ToplistComponent } from './components/user/toplist/toplist/toplist.component';
import { RulesComponent } from './components/about/rules/rules.component';
import { RatingComponent } from './components/about/rating/rating.component';
import { ContactComponent } from './components/about/contact/contact.component';
import { ThanksComponent } from './components/about/thanks/thanks.component';
import { GeneralComponent } from './components/about/general/general.component';

const routes: Routes = [
  {path: '', component: IntroComponent},
  {path: 'admin', component: AdminComponent},
  {path: 'admin-list', component: AdminListComponent},
  {path: 'admin-list-edit/:id', component: AdminComponent},
  {path: 'registrace', component: SignupComponent},
  {path: 'prihlaseni', component: LoginComponent},
  {path: 'hrej', component: PlayComponent},
  {path: 'hrej/:id', component: PlayComponent},
  {path: 'hry/:id', component: GamesComponent},
  {path: 'sin-slavy', component: ToplistComponent},
  {path: 'pravidla', component: RulesComponent},
  {path: 'skore', component: RatingComponent},
  {path: 'general', component: GeneralComponent},
  {path: 'kontakt', component: ContactComponent},
  {path: 'diky', component: ThanksComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],  
  exports: [RouterModule]
})

export class AppRoutingModule { }
