<div class="wrapper">

  <mat-card class="card-wrapper">
    <mat-card-header>
      <div mat-card-avatar> <mat-icon>games</mat-icon></div>
      <mat-card-title>NEJLEPŠÍ HRÁČI</mat-card-title>
      <mat-card-subtitle>Síň slávy</mat-card-subtitle>
    </mat-card-header>

    <div class="image">
      <img src="assets/images/icons/teamhappy.svg" alt="síň slávy">
    </div>

    <mat-card-content>

    <table mat-table [dataSource]="players">

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> Pořadí </th>
        <td mat-cell *matCellDef="let i=index"> {{i+1}}
          {{getSymbol(i+1)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> Jméno </th>
        <td mat-cell *matCellDef="let player"> {{player.username}} </td>
      </ng-container>

      <ng-container matColumnDef="rating">
        <th mat-header-cell *matHeaderCellDef> Skóre </th>
        <td mat-cell *matCellDef="let player"> {{player.rating}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </mat-card-content>

  </mat-card>

</div>

<app-latest-games></app-latest-games>

<div class="title-middle-games">
  CELKEM ODEHRÁNO HER
  <p>
  <span class="games bgc2">{{gamesCount}}</span>
  </p>
</div>
