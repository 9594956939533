import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class MessagesService {

  message: string;

  constructor(private snackBar: MatSnackBar) { }

  addMessage(message: string) {
    this.message = message;
  }

  getMessageListener() {
  }

  openSnackBar(message: string, button: string, duration: number, cssclass?: string[]) {
    this.snackBar.open(message, button, {
      duration: duration,
      panelClass: cssclass
    });
  }

  getMessage() {
    return this.message;
  }


}
