<div #scrollContainer class="background">
  <mat-card *ngIf="isIntroDisplayed && isUserAuthenticated" class="before-game">
    <mat-card-header>
      <div mat-card-avatar> <mat-icon>play_circle_outline</mat-icon></div>
      <mat-card-title>NOVÁ HRA</mat-card-title>
      <mat-card-subtitle>Pojďme na to!!</mat-card-subtitle>
    </mat-card-header>

    <div class="image">
      <img src="assets/images/icons/newgame2.svg">
    </div>

    <mat-card-content>
      <p>
        Pro hledání soupeře a spuštění hry klikni na tlačítko HREJ TEĎ.
      </p>
      <p>
        Chseš-li raději poměřit síly se svou známou, babičkou, kamarádem... klikni na VYTVOŘ HRU
      </p>
      <p>
        Hodně štěstí.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <div class="wrapper">
        <button [disabled]="searchingForGame" mat-raised-button color="accent" (click)="launchGame()">HREJ TEĎ</button>
        <span class="fill-remaining-space"></span>
        <button mat-raised-button color="primary" (click)="expandCreateGame = !expandCreateGame">VYTVOŘ HRU</button>
      </div>

    </mat-card-actions>
    <div *ngIf="expandCreateGame">
      <p>Pošli svému kamarádovi tento odkaz:</p>
      <p><mat-chip>{{url}}/{{myPlayerName}}</mat-chip></p>
      <p>A klikni tady:
        <button [disabled]="searchingForGame" mat-button (click)="createGame()">VYTVOŘIT HRU</button>
      </p>
    </div>
  </mat-card>


  <mat-card *ngIf="isIntroDisplayed && !isUserAuthenticated" class="before-game-anonymous">

    <mat-card-header>
      <mat-card-title>NOVÁ HRA</mat-card-title>
      <mat-card-subtitle>Pojďme na to!!</mat-card-subtitle>
    </mat-card-header>

    <div class="action-wrapper">
      <button mat-raised-button color="accent" routerLink="/registrace">Registrace</button>
      <button mat-raised-button color="warn"routerLink="/prihlaseni">Přihlášení</button>
    </div>

    <div class="image">
      <img src="assets/images/icons/newgame2.svg">
    </div>

    <form [formGroup]="anonymousForm" (submit)="launchAnonymousGame()" autocomplete="off">
      <mat-card-content>
        <p>
          Nejsi přihlášen - můžeš hrát, ale přicházíš o spoustu možností jako je tipování špatných odpovědí soupeře nebo posílání zastrašujících zpráv.
        </p>

        <mat-form-field class="full-width">
          <input matInput formControlName="playerName" type="text" placeholder="Jméno pro tuto hru">
          <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
          <mat-error *ngIf="anonymousForm.get('playerName').invalid">{{getPlayerNameError()}}</mat-error>
        </mat-form-field>
        <br /><br /><br />
        <div>Tvoje jméno pro tuto hru: <b>anonymous_{{anonymousForm.get('playerName').value}}</b></div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="accent" type="submit" class="button-full-width-large">HREJ TEĎ</button>
      </mat-card-actions>
    </form>

  </mat-card>

  <mat-card *ngIf="!isGameLaunched && !isIntroDisplayed" class="game">
    <mat-card-header>
      <div mat-card-avatar> <mat-icon>play_circle_outline</mat-icon></div>
      <mat-card-title>NOVÁ HRA</mat-card-title>
      <mat-card-subtitle>Pojďme na to!!</mat-card-subtitle>
    </mat-card-header>
    <div *ngIf="showBot && botCounter!==0" class="image">
      <img src="assets/images/icons/searching.svg">
    </div>

    <div *ngIf="showBot" class="waitingForBotGame">
      <div *ngIf="botCounter != 0  && botCounter < 3">
          Bot se připravuje...🤖
      </div>
      <div *ngIf='botCounter===0'>
        <p>
          Už necheš dále čekat než se připojí skutečný hráč? V tom případě klikni na tlačítko HREJ PROTI BOTOVI a spustí se hra proti počítači.
        </p>
        <p>
          Ale pozor!! Botové tě můžou lehce porazit. Jejich odpovědi jsou však do jisté míry dílem náhody.
        </p>
        <div class="waitingForBotGame">
          <button mat-raised-button color="accent" (click)="launchGameWithBot()">HREJ PROTI BOTOVI</button>
        </div>
      </div>
    </div>

    <div class="waitingForGame">
      <img src="../../../assets/images/icons/Bean Eater-2s-291px.svg" width="150px" />
    </div>

    <div class="waitingForGame">
      Hledá se soupeř...
    </div>

    
  </mat-card>

  <!-- GAME IS EITHER IN PROGRESS OR RESULT IS BEING PRINTED WITH CHAT POSSIBILITY -->
  <div *ngIf="isGameLaunched">
    <mat-card class="game"
      [ngClass]="{'game-loss': isGameFinished && gameResult==='loss',
                  'game-win': isGameFinished && gameResult==='win',
                  'game-draw': isGameFinished && gameResult==='draw'
                }">

      <!-- HEADER WITH PLAYERS INFO -->
      <mat-chip-list></mat-chip-list>
      <div class="game-headline-wrapper" fxHide.lt-sm="true">
        <div class="game-headline-item align-left"><mat-chip><mat-icon>account_circle</mat-icon> {{player1}}({{player1rating}})</mat-chip></div>
        <div class="game-headline-item center-space">VS.</div>
        <div class="game-headline-item align-right"><mat-chip>{{player2}}({{player2rating}}) <mat-icon>account_circle</mat-icon></mat-chip></div>
      </div>

      <!-- HEADER WITH PLAYERS INFO - SMALL DEVICES -->
      <div class="game-headline-wrapper-small" fxHide.gt-xs="true">
        <div><mat-chip><mat-icon>account_circle</mat-icon> {{player1}}({{player1rating}})</mat-chip></div>
        <div>VS.</div>
        <div><mat-chip>{{player2}}({{player2rating}}) <mat-icon>account_circle</mat-icon></mat-chip></div>
      </div>

      <!-- GAME IS IN PROGRESS -->
      <div *ngIf="!isGameFinished">
        <mat-card-header>
          <mat-card-title>{{round}}. kolo</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="counter">
            <app-counter [inputTime]="counterTime"></app-counter>
          </div>
          <div class="after-counter" *ngIf="hasAnswered && !hasOpponentAnswered">
            <mat-progress-bar mode="buffer"></mat-progress-bar>
            Čekání na odpověď hráče: {{opponentName}}.
          </div>
          <p>
            {{this.question.question}}
          </p>
          <!-- SPACE FOR IMAGE CATEGORY PLACED IN THE UPPER LEFT CORNER OF THE IMAGE -->
          <div class="imageCategory"><span class="imageCategory-text">{{question.category}}</span></div>

          <img mat-card-image [src]="this.question.imagePath" class="questionImage" >

          <!-- SPACE FOR IMAGE INFO (SOURCE) PLACED IN THE RIGHT BOTTOM OF THE IMAGE -->
          <div *ngIf="question.imageInfo" class="imageInfo"><span class="imageInfo-text">Zdroj: {{question.imageInfo}}</span></div>

          <!-- <mat-radio-group class="radio-group" [(ngModel)]="playerAnswer"> -->
          <mat-radio-group class="radio-group" [(ngModel)]="playerAnswer" [ngClass]="{
            'radio-group-no-info':!question.imageInfo
          }">
            <mat-radio-button class="radio-button" *ngFor="let answer of this.question.answers" [value]="answer">
              {{answer}}
            </mat-radio-button>
          </mat-radio-group>
        </mat-card-content>

        <mat-card-actions>
          <div class="action-buttons">
            <div class="answer-action-button">
              <button [disabled]="hasAnswered" mat-raised-button color="accent" (click)="confirmAnswer(playerAnswer)">POTVRDIT</button>
            </div>
            <div class="additional-action-buttons">
              <button [disabled]="!isUserAuthenticated || isTerrifiedClicked" mat-raised-button class="primary-color" (click)="sendTerrifyingMessage()">ZASTRAŠIT <span>🐞</span></button>
              <button [disabled]="isLoveClicked" mat-raised-button class="primary-color" (click)="sendLoveMessage()">POSLAT <span>💝</span></button>
              <button [disabled]="!isUserAuthenticated || isWrongClicked" mat-raised-button class="primary-color" (click)="guessWrongAnswer()">Odpoví <span>❌</span></button>
            </div>
          </div>
        </mat-card-actions>
      </div>
      <!-- GAME IS FINISHED -->

      <!-- RESULT OF FINISHED GAME -->
      <div *ngIf="isGameFinished">
        <div class="game-final-points">
        {{getPointsFinalResults()}}
        </div>

        <div class="game-final-results">
          {{getWordFinalResults()}}
        </div>

        <div *ngIf="isUserAuthenticated" class="game-final-rating">
          Skóre <br/>
          <button mat-fab color="primary">
          {{getSignRatingDifference()}} {{ratingDifference}}
          </button>
        </div>

        <div *ngIf="!isUserAuthenticated" class="game-final-rating-anonymous">
          <div>
            Hraješ jako anonymní hráč, žádné skóre se ti tedy nepočítá a nemáš více možností jak ovlivnit hru - není to škoda?
          </div>
          <button class="primary-color" mat-raised-button routerLink="/registrace">Registrace</button>
        </div>

        <div class="wrapper">
          <button mat-raised-button color="accent" (click)="suggestRematch()">ODVETA</button>
          <span class="fill-remaining-space"></span>
          <button [disabled]="searchingForGame" mat-raised-button color="accent" (click)="newGame()">NOVÁ HRA</button>
        </div>

        <div *ngIf="isWaitingForRematch">
          <br />
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          Čekám na soupeřovu odpověď.
        </div>

      </div>
      <!-- END OF RESULT OF FINISHED GAME -->
    </mat-card>
    <!-- END OF GAME CARD -->

    <div *ngIf="isGameFinished">
      <app-chat [myPlayerName] = "myPlayerName"
                [opponentName] = "opponentName">
      </app-chat>
      <app-social></app-social>
      <app-results [results]="results"></app-results>
    </div>

  </div>
  <!-- END OF GAME IS EITHER IN PROGRESS OR RESULT IS BEING PRINTED WITH CHAT POSSIBILITY -->
</div>
