<!--  <p>social works!</p>
<button mat-raised-button class="social-button" onclick="window.open('http://www.facebook.com/sharer.php?u=https://onlinekviz.cz','','width=500, height=300')">
    <mat-icon class="fb-icon">facebook</mat-icon>
    SDÍLET
</button> -->
<!-- <div class="fb-share-button" data-href="https://onlinekviz.cz" data-layout="button" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fonlinekviz.cz%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Sdílet</a></div> -->

<!-- <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fonlinekviz.cz&width=450&layout=standard&action=like&size=large&share=true&height=35&appId" width="450" height="35" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>


<iframe src="http://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fonlinekviz.cz&amp;layout=standard&amp;show_faces=false&amp;width=450&amp;action=like&amp;colorscheme=light&amp;height=35" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:450px; height:35px;" allowTransparency="true"></iframe>


<iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FRICHBOREAL&width=450&layout=standard&action=like&size=large&share=true&height=35&appId" width="450" height="35" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>  -->

<div class="social">
    <div class="social-item">
        <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fonlinekviz.cz&layout=button&size=large&width=77&height=28&appId" width="77" height="28" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
    <!-- <div class="social-item">
        <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fonlinekviz.cz&width=72&layout=button&action=like&size=large&share=false&height=65&appId" width="72" height="65" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div> -->
</div>